import React, { useState } from 'react'
import Sidebar from '../Componants/Sidebar';
import Topbar from '../Componants/Topbar';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../Componants/AuthProvider';
import { Navigate } from 'react-router-dom';



const ArbDashboard = () => {

    const [selectedMenuItem, setSelectedMenuItem] = useState('Dashboard');
    const [isOpen, setIsOpen] = useState(true);
    const { user } = useAuth();

    if (!user) {
      return <Navigate to="/" />;
    }

    const menuItems = [
        {
            name: 'Dashboard',
            icon: 'home', // Material Icon name
            link: '/arbdashboard',
        },
        {
            name: 'Acceptance Letter',
            icon: 'handshake',
            submenu: [
                { name: 'Generate AL', link: '/arbdashboard/acceptletter/generate_al', icon: 'person' },
                { name: 'Services', link: '/arbdashboard/acceptletter/services', icon: 'security' },
                { name: 'Reports', link: '/arbdashboard/acceptletter/reports', icon: 'description' },
            ]
        },
        // {
        //     name: 'Acceptance Letter',
        //     icon: 'handshake',
        //     link: '/arbdashboard/acceptletter',
        // },
        {
            name: 'Section 17 Order',
            icon: 'list_alt',
            link: '/arbdashboard/section17order',
        },
        {
            name: 'Final Hearing Notice',
            icon: 'summarize',
            link: '/arbdashboard/finalhearingnotice',
        },
        {
            name: 'Virtual Meeting',
            icon: 'videocam',
            link: '/arbdashboard/virtualmeeting',
        }
    ];


    const handleMenuItemClick = (name) => {
        setSelectedMenuItem(name);
      };

      const toggleSidebar = () => {
        setIsOpen(!isOpen);
      };
      
    return (
        <div>
            <Topbar isOpen={isOpen}/>
            <Sidebar menuItems={menuItems} user={user.User_name} onMenuItemClick={handleMenuItemClick} isOpen={isOpen} toggleSidebar={toggleSidebar}/>       
            <div className= {`${isOpen?"":"sideCollapse"} mainn-content p-3`}>           
            <Outlet />
            </div>
        </div>
    )
}

export default ArbDashboard
