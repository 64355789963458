import React from "react";
import Pagination from "react-bootstrap/Pagination";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ReusableTable = ({
  data,
  currentPage,
  pageNumbers,
  setCurrentPage,
}) => {
  // Get the table headers from the first item in the data array
  const headers = data.length > 0 ? Object.keys(data[0]) : [];

  // Pagination logic to show only 5 buttons at a time
  const totalPages = pageNumbers.length;
  const maxPagesToShow = 5;

  const startPage = Math.max(
    1,
    Math.min(
      currentPage - Math.floor(maxPagesToShow / 2),
      totalPages - maxPagesToShow + 1
    )
  );
  const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

  const displayedPages = pageNumbers.slice(startPage - 1, endPage);

  // Handlers for arrow clicks
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to format ISO date strings
  const formatDate = (dateString) => {
    // Check if the value is a valid ISO date string
    if (typeof dateString === "string" && dateString.includes("T")) {
      return dateString.split("T")[0]; // Return the date part (YYYY-MM-DD)
    }
    return dateString; // Return the original value if it's not a date
  };

  return (
    <div className="table-container">
      <div className="table-wrapper">
        <table className="responsive-table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    maxHeight: "50px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  className="text-center custom_fz"
                >
                  {headers.map((header) => (
                    <td
                      key={header}
                      style={{
                        textAlign:
                          header === "CUST_NAME" || header === "assignedArbitrator"  
                            ? "left"
                            : "center",
                      }}
                      // style={{
                      //   textAlign:
                      //     header === "assignedArbitrator" ||
                      //     header === "CUST_NAME"
                      //       ? "left"
                      //       : "center",
                      // }}
                    >
                      {formatDate(item[header])}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {pageNumbers.length > 1 && (
        <Pagination className="justify-content-center">
          {/* Left Arrow */}
          <Pagination.Prev onClick={handlePrevious} disabled={currentPage === 1}>
            <FaChevronLeft />
          </Pagination.Prev>

          {/* Dynamic range of page numbers */}
          {displayedPages.map((number) => (
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </Pagination.Item>
          ))}

          {/* Right Arrow */}
          <Pagination.Next
            onClick={handleNext}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </Pagination.Next>
        </Pagination>
      )}
    </div>
  );
};

export default ReusableTable;
