import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AddArbInfo from "./AddArbInfo";
import ArbitratorList from "./ArbitratorList";
import NewformTrial from "./NewformTrial";

const Arbitrators = () => {
  const [showAddArbInfo, setShowAddArbInfo] = useState(false);

  const handleAddArbitrator = () => setShowAddArbInfo(true);


  const handleShowArbitratorList = () => setShowAddArbInfo(false);

  return (
    <div>
      <div className="container-fluid">
      {!showAddArbInfo && (
        <>
        <div className="row mt-3 mb-3 ">
          <div className="col-md-10 mx-auto d-flex justify-content-between">
            <h4 className="text-center "> Arbitrator List</h4>
            <button className="btn custBtn" onClick={handleAddArbitrator}>
              <div className="d-flex align-items-center">
                <span className="material-icons pe-2"> add</span>
                <span> Add Arbitrator </span>
              </div>
            </button>
          </div>
        </div>
      
        <ArbitratorList />
        </>
    )}

        {showAddArbInfo && (
            <AddArbInfo />
        )}

      </div>
    </div>
  );
};

export default Arbitrators;
