import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Pagination } from "react-bootstrap";
import LoadingSpinner from "./LoadingSpinner";
import "./uploadexcel.css";
import { toast, ToastContainer } from "react-toastify";
import { FaWhatsapp } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { FaRegMessage } from "react-icons/fa6";
import { FaPrint } from "react-icons/fa";
import { Worker } from "@react-pdf-viewer/core";
// Import the main Viewer component
import { Viewer } from "@react-pdf-viewer/core";

// Import the Viewer styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import { API_BASE_URL } from "../utils/constants";

const ReferenceDraft = () => {
  const [data, setData] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]); //the clients Data to populate dropdown
  const [selectedProduct, setSelectedProduct] = useState([]); //to add the product from the dropdown
  const [arbitrator, setArbitrator] = useState([]); //to add the product from the dropdown
  const [whatsappStatus, setWhatsappStatus] = useState([]); //to add the product from the dropdown
  const [error, setError] = useState(null);
  const [error1, setError1] = useState(null);
  const [error2, setError2] = useState(null);
  const [selectedProductID, setSelectedProductID] = useState(null);
  const [selectedClientID, setSelectedClientID] = useState(null);
  const [selectedLotNo, setSelectedLotNo] = useState(null);
  const [selectedArbitratorID, setSelectedArbitratorID] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [status, setStatus] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  // For Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // To fetch Clients DaTA
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(
          // "http://arb.resolutionexperts.in/api/Client"
          `${API_BASE_URL}/api/Client`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const parsedClient = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        // console.log(parsedClient);
        setSelectedClient(parsedClient);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  console.log(selectedClient);

  // API Call for Product data
  useEffect(() => {
    const fetchProduct = async () => {
      // setLoading(true);
      setError(null); // Reset error state
      try {
        const response = await fetch(
          // `http://arb.resolutionexperts.in/api/products?client_id=${selectedClientID}`
          `${API_BASE_URL}/api/products?client_id=${selectedClientID}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const parsedProducts = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        console.log(parsedProducts);
        setSelectedProduct(parsedProducts); // Set products data
      } catch (error) {
        setError1(error);
      } finally {
        setLoading1(false);
      }
    };

    fetchProduct();
  }, [selectedClientID]);

  // API Call for Arbitrator
  useEffect(() => {
    const fetchArbitrator = async () => {
      // setLoading(true);
      setError(null); // Reset error state
      try {
        const response = await fetch(
          // "http://arb.resolutionexperts.in/api/arbitrator"
          `${API_BASE_URL}/api/arbitrator`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const parsedArbitrators = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        console.log(parsedArbitrators);
        setArbitrator(parsedArbitrators); // Set products data
      } catch (error) {
        setError1(error);
      } finally {
        setLoading(false);
      }
    };

    fetchArbitrator();
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(data.length / itemsPerPage));
  }, [data, itemsPerPage]);

  console.log(arbitrator);

  // OnChange Events

  const handleSelectChange = (e) => {
    const selectedID = e.target.value;
    setSelectedClientID(selectedID);
  };

  const handleProductChange = (e) => {
    const selectedID = e.target.value;
    setSelectedProductID(selectedID);
  };

  const handleArbitratorChange = (e) => {
    const selectedID = e.target.value;
    setSelectedArbitratorID(selectedID);
  };

  const handleLotNoChange = (e) => {
    const selectedID = e.target.value;
    setSelectedLotNo(selectedID);
  };

  console.log(selectedClientID);
  console.log(selectedProductID);
  console.log(selectedLotNo);
  console.log(selectedArbitratorID);

  // Data to be sent for detail
  // const dataToBeSent = {
  //   Client_id: selectedClientID,
  //   Product_id: selectedProductID,
  //   Lot_No: selectedLotNo,
  //   Arbitrator_id: selectedArbitratorID,
  // };

  // Show Data Logic Goes here
  const handleData = async () => {
    setLoading(true); // Start loading before fetching data
    try {
      const response = await fetch(
        // `http://arb.resolutionexperts.in/api/RefLots?Lot_no=1&Client_id=1&Product_id=2&Arb_id=1`
        // `http://arb.resolutionexperts.in/api/RefLots?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`
        `${API_BASE_URL}/api/RefLots?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`
        
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      const parsedData = Array.isArray(result) ? result : JSON.parse(result);
      const updatedData = parsedData.map((item) => {
        const { assign_id, Arbitrator_id, Case_id, UPLODED_DATE, ...rest } =
          item;
        return {
          ...rest,
        };
      });
      console.log(updatedData);
      setData(updatedData);
      setTotalPages(Math.ceil(updatedData.length / itemsPerPage));
      setShowTable(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(data);
  const headers = data.length > 0 ? Object.keys(data[0]) : [];
  console.log(headers);

  const uploadData = data.map((item) => ({
    Client_id: item.Client_id,
    product_id: item.product_id,
    LOT_NO: item.LOT_NO,
  }));



  // Data for whats app Mobile no and Reference no
  const dataForWhatsapp = data.map((item) => ({
    MOBILE_NO_1: item.MOBILE_NO_1,
    REFERENCE_NO: item.REFERENCE_NO,
  }));



  // Loading Spinner Compenent
  if (loading) return <LoadingSpinner />;

  // to handle the generation of reference Draft
  const handleGenerateDraft = async () => {
    setLoading(true); // Start loading before fetching data
    try {
      // Fetch the PDF file from the API
      const response = await fetch(
        // "http://192.168.9.135:8000/api/Refletter?Lot_no=1&Client_id=1&Product_id=2&Arb_id=1"
        // `http://192.168.43.135:8000/api/Refletter?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`
        // `http://88.135.73.195/api/Refletter?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`
        `${API_BASE_URL}/api/Refletter?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      //  console.log( `http://88.135.73.195/api/Refletter?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`);
       
      // Convert the response to a Blob
      const pdfBlob = await response.blob();
    
      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
   
      // Set the PDF URL to the state
      setPdfUrl(pdfUrl);
      setShowPDF(true);
    } catch (error) {
      console.error("Error fetching and displaying the PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function for upload thr ReferenceDraft
  const handleUploadDraft = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        // "http://192.168.9.135:8000/api/SaveRef?Lot_no=1&Client_id=1&Product_id=2&Arb_id=1"
        // `http://192.168.9.135:8000/api/SaveRef?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`
        // `http://192.168.43.135:8000/api/SaveRefCase?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`
        // `http://88.135.73.195/api/SaveRefCase?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`
        `${API_BASE_URL}/api/SaveRefCase?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
        );
      }

      const result = await response.json(); // Process the response
      console.log(result);
      setShowServices(true);
    } catch (error) {
      console.error("Error uploading data:", error);
      alert(`Error uploading data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // to handle the services
  const handleServices = () => {
    setShow(true);
  };

  // To print the Reference Draft
  const handlePrint = () => {
    alert("printing");
    toast.success("Printing", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  };


  // To Mail The Reference Draft
  const handleMail = async() => {
    const dataForMail = data.map((item) => ({
      Ref_no: item.REFERENCE_NO,
      E_mail_id: item.E_MAIL_ID,
      Service_id: "1",
    }));
    console.log(dataForMail);
    try {
      const response = await fetch(
        "http://192.168.43.135:8000/api/Services/Mail",
       
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ case: dataForMail }),
        }
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
        );
      }
      const result = await response.json(); // Process the response
      console.log("Upload response:", result);
      // alert("Mail Successful")
      toast.success("Mail Sent Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    } catch (error) {
      console.error("Error uploading data:", error);
      // alert(`Error uploading data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // To Whatsapp the Reference Draft
  const handleWhatsapp = () => {
    // setLoading(true);
    dataForWhatsapp.map((item) => {
      fetch(
        `https://whatsapp.xpressdigital.online/api/send?number=9182377799360&type=media&message=test+message&media_url=http://arb.resolutionexperts.in/Content/Cases/Ref/Axis-Lot-30-ARB-1-2024-1.pdf&filename=Axis-Lot-30-ARB-1-2024-1.pdf&instance_id=66A79167753A3&access_token=66a791382d52c`
        // `https://whatsapp.xpressdigital.online/api/send?number=91${item.MOBILE_NO_1}&type=media&message=test+message&media_url=http://arb.resolutionexperts.in/Content/Cases/Ref/${item.REFERENCE_NO}.pdf&filename=${item.REFERENCE_NO}.pdf&instance_id=66A79167753A3&access_token=66a791382d52c`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "Network response was not ok " + response.statusText
            );
          }
          return response.json(); // or response.text() or response.blob(), etc.
        })
        .then((data) => {
          console.log(data.status);
          // Handle the data here
          if (data.status == "success") {
            toast.success("WhatsApp Message Sent Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "colored",
            });
            setStatus(true);
            // setLoading(false); 
          }
          setWhatsappStatus(data);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
      //   .finally {
      //   setLoading(false);
      // };
    });
  };

  // const handleWhatsapp = async () => {
  //   setLoading(true);
  //   let completedRequests = 0;
  //   const totalRequests = dataForWhatsapp.length;
    
  //   if (totalRequests === 0) {
  //     setLoading(false);
  //     return;
  //   }
  
  //   const sendMessage = async (item) => {
  //     try {
  //       const response = await fetch(
  //         `https://whatsapp.xpressdigital.online/api/send?number=91${item.MOBILE_NO_1}&type=media&message=test+message&media_url=http://arb.resolutionexperts.in/Content/Cases/Ref/${item.REFERENCE_NO}.pdf&filename=${item.REFERENCE_NO}.pdf&instance_id=66A79167753A3&access_token=66a791382d52c`
  //       );
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok " + response.statusText);
  //       }
  //       const data = await response.json();
  //       console.log(data.status);
  //       // Handle the data here
  //       if (data.status === "success") {
  //         toast.success("WhatsApp Message Sent Successfully", {
  //           position: toast.POSITION.BOTTOM_RIGHT,
  //           theme: "colored",
  //         });
  //         setStatus(true);
  //       }
  //       setWhatsappStatus(data);
  //     } catch (error) {
  //       console.error("There was a problem with the fetch operation:", error);
  //     } finally {
  //       completedRequests += 1;
  //       if (completedRequests === totalRequests) {
  //         setLoading(false);
  //       }
  //     }
  //   };
  
    // Use a loop to handle each request
    // for (const item of dataForWhatsapp) {
    //   await sendMessage(item);
    // }
  // };
  


  // To text message the reference DRAFT
  const handleMessage = () => {
    toast.success("Message Sent Successfully!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPagesToShow = 5; // Maximum number of page buttons to show

  const generatePaginationItems = () => {
    const pageItems = [];
    let startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

    if (endPage - startPage + 1 < totalPagesToShow) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  return (
    <>
      <div className="container">
        {/* Top Lable */}
        <div className="row">
          <div className="col-md-12">
            <h3 className="my-3 ms-4">Generate Reference Draft</h3>
          </div>
        </div>

        {/* Form is in here  to select Lot and all */}
        <div className="row align-items-center justify-content-evenly my-3">
          <div className="col-md-5">
            <div className="row">
              {/* To Select The Client Bank */}
              <div className="col-md-6">
                {/* <Form.Label htmlFor="" className="ps-2">
              Select Bank
            </Form.Label> */}
                <Form.Select
                  aria-label="Default select example"
                  onChange={handleSelectChange}
                  className="custom_input"
                >
                  <option value="" selected>
                    Choose a Bank
                  </option>
                  {selectedClient.map((item) => (
                    <option key={item.Client_id} value={item.Client_id}>
                      {item.client_name}
                    </option>
                  ))}
                </Form.Select>
              </div>

              {/* To Select The pRoduct Of the Bank */}
              <div className="col-md-6">
                {/* <Form.Label htmlFor="" className="ps-2">
              Select the Product
            </Form.Label> */}
                <Form.Select
                  aria-label="Default select example"
                  onChange={handleProductChange}
                  className="custom_input"
                >
                  <option value="" selected>
                    Choose a Product
                  </option>
                  {selectedProduct.map((item) => (
                    <option key={item.Product_id} value={item.Product_id}>
                      {item.Product_name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
          </div>

          {/* To enter the Lot No */}
          <div className="col-md-2">
            {/* <Form.Label htmlFor="" className="ps-2">
              Enter the Lot No
            </Form.Label> */}
            <Form.Control
              type="number"
              className="custom_input"
              placeholder="Enter Lot No"
              onChange={handleLotNoChange}
            />
          </div>

          {/* To Get The arbitrator */}
          <div className="col-md-3">
            {/* <Form.Label htmlFor="" className="ps-2">
              Select The Arbitrator
            </Form.Label> */}
            <Form.Select
              aria-label="Default select example"
              onChange={handleArbitratorChange}
              className="custom_input"
            >
              <option value="" selected>
                Choose Arbitrator
              </option>
              {arbitrator.map((item) => (
                <option key={item.Arb_id} value={item.Arb_id}>
                  {item.Arb_name}
                </option>
              ))}
            </Form.Select>
          </div>

          {/* For Button */}
          <div className="col-md-2">
            {/* <Form.Label htmlFor="" className="ps-2 cust_label">
              Select
            </Form.Label> */}
            {!showTable && (
              <button
                className="btn-info"
                onClick={handleData}
              >
                {/* <a href="http//arb.resolutionexperts.in/Content/Reference_Letter_1.pdf" target="_blank">Get Data</a> */}
                Show Data
              </button>
            )}
            {showTable && !showPDF && (
              <Button
                className="btn btn-primary getDataBtn"
                onClick={handleGenerateDraft}
              >
                Generate RD
              </Button>
            )}
            {showTable && showPDF && !showServices && (
              <Button
                className="btn btn-primary getDataBtn"
                onClick={handleUploadDraft}
              >
                Upload RD
              </Button>
            )}
            {/* {showServices && (
              <button
                className="btn-info"
                onClick={handleServices}
              >
                Services
              </button>
            )} */}
          </div>
        </div>

        {/* to show the data in table  */}
        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="table-responsive">
              {data.length > 0 && !showPDF && (
                <table className="table table-striped table-bordered table-hover mt-3 text-center">
                  <thead>
                    <tr>
                      {headers.map((header) => (
                        <th key={header}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          maxHeight: "50px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        className="text-center custom_fz"
                      >
                        {headers.map((header) => (
                          <td key={header}>{item[header]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            {totalPages > 1 && !showPDF && (
              <Pagination className="justify-content-center">
                <Pagination.Prev
                  onClick={() =>
                    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))
                  }
                />
                {generatePaginationItems()}
                <Pagination.Next
                  onClick={() =>
                    setCurrentPage((prev) =>
                      prev < totalPages ? prev + 1 : prev
                    )
                  }
                />
              </Pagination>
            )}
          </div>
        </div>

        {showPDF && !showServices && (
          <div className="row">
            <div className="col-md-12">
              {pdfUrl && (
                <iframe
                  src={pdfUrl}
                  style={{ width: "100%", height: "100vh" }}
                  title="PDF Viewer"
                />
              )}
            </div>
          </div>
        )}

        {/* Modal */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton className="text-center">
            <Modal.Title>Send Reference Draft</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center d-flex justify-content-center my-3">
            <div
              className="modalIcon shadow-lg pt-3 rounded mx-3"
              onClick={handlePrint}
            >
              <FaPrint className="fs-2 mx-4 text-info" />
              <p className="mt-3">REPD</p>
            </div>
            <div
              className="modalIcon shadow-lg pt-3 rounded mx-3"
              onClick={handleMail}
            >
              <CiMail className="fs-2 mx-4 text-danger" />
              <p className="mt-3">Mail</p>
            </div>
            <div className="modalIcon shadow-lg pt-3 rounded mx-3">
              <FaWhatsapp
                className="fs-2 mx-4 text-success shadow-xl"
                onClick={handleWhatsapp}
              />
              <p className="mt-3">Whats App</p>
            </div>
            <div className="modalIcon shadow-lg pt-3 rounded mx-3">
              <FaRegMessage
                className="fs-2 mx-4 text-secondary shadow-xl"
                onClick={handleMessage}
              />
              <p className="mt-3">Message</p>
            </div>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default ReferenceDraft;






