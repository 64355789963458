import React, { useState, useRef } from 'react';
import './FileUploadForm.css';

function FileUploadForm() {
  const [comment, setComment] = useState('');
  const [videos, setVideos] = useState([]);
  const [files, setFiles] = useState([]);

  const videoInputRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleCommentChange = (e) => setComment(e.target.value);

  const handleVideoChange = (e) => {
    const selectedVideos = Array.from(e.target.files);
    setVideos((prevVideos) => [...prevVideos, ...selectedVideos]);
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleRemoveFile = (index, type) => {
    if (type === 'file') {
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    } else if (type === 'video') {
      setVideos((prevVideos) => prevVideos.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Comment:', comment);
    console.log('Videos:', videos);
    console.log('Files:', files);
    // Handle the form submission logic
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div className="form-group">
        <label htmlFor="comment" className="form-label">
          Add Comment
        </label>
        <textarea
          id="comment"
          rows="4"
          className="textarea"
          placeholder="Write your comment here..."
          value={comment}
          onChange={handleCommentChange}
        ></textarea>
      </div>

      <div className="form-group flex-container">
        <label htmlFor="videoUpload" className="form-label">
          Upload Videos
        </label>
        <button
          type="button"
        //   className="upload-button"
          className="custBtn"
          onClick={() => videoInputRef.current.click()}
        >
          Add Videos
        </button>
        <input
          type="file"
          id="videoUpload"
          ref={videoInputRef}
          accept="video/*"
          multiple
          className="hidden-input"
          onChange={handleVideoChange}
        />
      </div>

      {videos.length > 0 && (
        <div className="file-list">
          {/* <h4>Selected Videos:</h4> */}
          <ul>
            {videos.map((video, index) => (
              <li key={index} className="file-item">
                <span>{video.name}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveFile(index, 'video')}
                  className="remove-button"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="form-group flex-container">
        <label htmlFor="fileUpload" className="form-label">
          Upload PDF Files
        </label>
        <button
          type="button"
        //   className="upload-button"
          className="custBtn"
          onClick={() => fileInputRef.current.click()}
        >
          Add PDF Files
        </button>
        <input
          type="file"
          id="fileUpload"
          ref={fileInputRef}
          accept="application/pdf"
          multiple
          className="hidden-input"
          onChange={handleFileChange}
        />
      </div>

      {files.length > 0 && (
        <div className="file-list">
          {/* <h4>Selected PDF Files:</h4> */}
          <ul>
            {files.map((file, index) => (
              <li key={index} className="file-item">
                <span>{file.name}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveFile(index, 'file')}
                  className="remove-button"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <button
        type="submit"
        className="submit-button"
      >
        Save Details
      </button>
    </form>
  );
}

export default FileUploadForm;
