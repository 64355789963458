import React from 'react'

const BankDashboardMain = () => {
  return (
    <div>
      <h1> Bank Dashboard Main Page</h1>
    </div>
  )
}

export default BankDashboardMain
