// AnimatedButton.js
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaUpload, FaCheck } from 'react-icons/fa';
import { FiLoader } from 'react-icons/fi';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Button = styled.button`
  background-color: ${({ stage }) => (stage === 'done' ? 'green' : 'blue')};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: ${({ stage }) => (stage === 'done' ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color 0.3s ease;

  width: 150px; /* Fixed width */
  height: 50px; /* Fixed height */

  &:disabled {
    cursor: not-allowed;
  }
`;

const Spinner = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${rotate} 1s linear infinite;
  margin-right: 10px;
`;

const Trial = ({ onClick }) => {
  const [stage, setStage] = useState('upload');

  const handleClick = () => {
    if (stage === 'upload') {
      setStage('loading');
      setTimeout(() => {
        setStage('done');
      }, 2000); // Simulate loading
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      onClick={handleClick}
      stage={stage}
      disabled={stage === 'done'}
    >
      {stage === 'loading' && <Spinner />}
      {stage === 'loading' && 'Loading'}
      {stage === 'upload' && <FaUpload style={{ marginRight: '10px' }} />}
      {stage === 'upload' && 'Upload'}
      {stage === 'done' && <FaCheck style={{ marginRight: '10px' }} />}
      {stage === 'done' && 'Done'}
    </Button>
  );
};

export default Trial;


















// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import { FaCloudUploadAlt } from 'react-icons/fa';

// const Trial = () => {
//   const [stage, setStage] = useState('upload');

//   const handleClick = () => {
//     setStage('loading');
//     setTimeout(() => {
//       setStage('done');
//       setTimeout(() => {
//         setStage('upload');
//       }, 1000); // Reset to upload after 1 second
//     }, 2000); // Simulate loading for 2 seconds
//   };

//   const buttonVariants = {
//     upload: {
//       scale: 1,
//       backgroundColor: '#007bff',
//       transition: { type: 'spring', stiffness: 300 },
//     },
//     loading: {
//       scale: 1.1,
//       backgroundColor: '#28a745',
//       transition: { type: 'spring', stiffness: 300, damping: 10 },
//     },
//     done: {
//       scale: 1,
//       backgroundColor: '#ffc107',
//       transition: { type: 'spring', stiffness: 300 },
//     },
//   };

//   return (
//     <motion.button
//       onClick={handleClick}
//       className="animated-button"
//       variants={buttonVariants}
//       animate={stage}
//       style={{
//         padding: '10px 20px',
//         border: 'none',
//         borderRadius: '25px',
//         color: '#fff',
//         cursor: 'pointer',
//         display: 'flex',
//         alignItems: 'center',
//       }}
//     >
//       {stage === 'upload' && <FaCloudUploadAlt style={{ marginRight: '8px' }} />}
//       {stage === 'upload' && 'Upload'}
//       {stage === 'loading' && 'Loading...'}
//       {stage === 'done' && 'Done!'}
//     </motion.button>
//   );
// };

// export default Trial;























// import React, { useEffect, useState } from 'react'

// const Trial = () => {
//   const[report, setReport] = useState([]);
//   const[error, setError] = useState(false);

//   useEffect(() => {
//         const fetchArbitrators = async () => {
//           try {
//             const response = await fetch(
//               "https://whatsapp.xpressdigital.online/api/send?number=918237779960&type=media&message=test+message&media_url=https%3A%2F%2Fi.pravatar.cc&filename=file_test.jpg&instance_id=66A79167753A3&access_token=66a791382d52c"
//             );
//             if (!response.ok) {
//               throw new Error("Network response was not ok");
//             }
//             const result = await response.json();
//             const parsedReport = Array.isArray(result)
//               ? result
//               : JSON.parse(result); // Ensure parsedArbitrators is an array
//             console.log(parsedReport);
//             setReport(parsedReport);
//           } catch (error) {
//             setError(error.message);
//           }
//         };
    
//         fetchArbitrators();
//       }, []);


//   return (
//     <div>Trial</div>
//   )
// }

// export default Trial







// import React from "react";
// import { toast, ToastContainer } from "react-toastify"; // Importing toastify module
// import "react-toastify/dist/ReactToastify.css"; // Import toastify css file

// // toast-configuration method,
// // it is compulsory method.
// // toast.configure();

// const Trial = () => {
//   const notify = () => {
//     // Calling toast method by passing string
//     toast.success("Hello Geeks");
//   };
//   return (
//     <>
//     <div className="GeeksforGeeks">
//       <button onClick={notify}>Click Me!</button>
//       <ToastContainer />
//     </div>
//     <div className="row my-5">
//         <div className="col">
//           <button
//             className="custom_button"

//           >
//             Upload 1
//           </button>

//           <button
//             className="custom_button1"

//           >
//             Upload 2
//           </button>

//           <button
//             className="custom_button2"

//           >
//             Upload 3
//           </button>

//           <button
//             className="custom_button3"

//           >
//             Upload 4
//           </button>

//           <button
//             className="custom_button4"

//           >
//             Upload 5
//           </button>

//           <button
//             className="custom_button5"

//           >
//             Upload 6
//           </button>

//           <button
//             className="custom_button6 mt-5"

//           >
//             Upload 7
//           </button>

//           <button
//             className="custom_button7 mt-5"

//           >
//             Upload 8
//           </button>

//           <button class="glass-button-primary">Primary Button</button>
//         </div>

//         <div class="button-container my-5">
//           <button class="button primary">Primary</button>
//           <button class="button secondary">Secondary</button>
//           <button class="button outlined">Outlined</button>
//           <button class="button ghost">Ghost</button>
//         </div>

//         <div class="button-container_crud my-5">
//           <button class="glass-button_crud create">Create</button>
//           <button class="glass-button_crud read">Read</button>
//           <button class="glass-button_crud update">Update</button>
//           <button class="glass-button_crud delete">Delete</button>
//         </div>

//         <div class="button-container1 my-5">
//           <button class="glass-button1 primary">Primary Button</button>
//           <button class="glass-button1 secondary">Secondary Button</button>
//           <button class="glass-button1 accent-1">Accent Button 1</button>
//           <button class="glass-button1 accent-2">Accent Button 2</button>
//           <button class="glass-button1 accent-3">Accent Button 3</button>
//           <button class="glass-button1 accent-4">Accent Button 4</button>
//           <button class="glass-button1 accent-5">Accent Button 5</button>
//         </div>

//         <div class="button-container">
//           <button class="glass-button red">Red</button>
//           <button class="glass-button green">Green</button>
//           <button class="glass-button blue">Blue</button>
//           <button class="glass-button yellow">Yellow</button>
//           <button class="glass-button purple">Purple</button>
//           <button class="glass-button orange">Orange</button>
//           <button class="glass-button cyan">Cyan</button>
//         </div>

//         <div class="button-container2  my-5">
//           <button class="glass-button red">Red Button</button>
//           <button class="glass-button green">Green Button</button>
//           <button class="glass-button blue">Blue Button</button>
//           <button class="glass-button yellow">Yellow Button</button>
//           <button class="glass-button purple">Purple Button</button>
//           <button class="glass-button orange">Orange Button</button>
//           <button class="glass-button cyan">Cyan Button</button>
//         </div>

//         <div class="button-container3 my-5">
//           <button class="glass-button navy">Navy</button>
//           <button class="glass-button teal">Teal</button>
//           <button class="glass-button maroon">Maroon</button>
//           <button class="glass-button olive">Olive</button>
//           <button class="glass-button charcoal">Charcoal</button>
//           <button class="glass-button purple">Purple</button>
//           <button class="glass-button slate">Slate</button>
//         </div>

//         <div class="button-container2 my-5">
//           <button class="glass-morph-button red">Red Button</button>
//           <button class="glass-morph-button green">Green Button</button>
//           <button class="glass-morph-button blue">Blue Button</button>
//           <button class="glass-morph-button yellow">Yellow Button</button>
//           <button class="glass-morph-button purple">Purple Button</button>
//           <button class="glass-morph-button orange">Orange Button</button>
//           <button class="glass-morph-button cyan">Cyan Button</button>
//         </div> 
//       </div>
//     </>
//   );
// };

// export default Trial;

// import React, { useEffect, useState } from "react";

// const Trial = () => {
//   const [arbitrators, setArbitrators] = useState([]);
//   const [unassignedLots, setUnassignedLots] = useState([]);
//   const [unassignedData, setUnassignedData] = useState([]);
//   const [unassignedLotsData, setUnassignedLotsData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [error1, setError1] = useState(null);
//   const [error2, setError2] = useState(null);
//   const [selectedLot, setSelectedLot] = useState("");
//   const [showList, setShowList] = useState(false);

//   // Arbitrator Fetch Code
//   useEffect(() => {
//     const fetchArbitrators = async () => {
//       try {
//         const response = await fetch(
//           "http://arb.resolutionexperts.in/api/arbitrator"
//         );
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         const result = await response.json();
//         const parsedArbitrators = Array.isArray(result)
//           ? result
//           : JSON.parse(result); // Ensure parsedArbitrators is an array
//         console.log(parsedArbitrators);
//         setArbitrators(parsedArbitrators);
//       } catch (error) {
//         setError(error.message);
//       }
//     };

//     fetchArbitrators();
//   }, []);

//   // unassignedLots Fetch Code
//   useEffect(() => {
//     const fetchArbitrators = async () => {
//       try {
//         const response = await fetch(
//           "http://arb.resolutionexperts.in/api/unassignLots"
//         );
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         const result = await response.json();
//         const parsedUnassignedLots = Array.isArray(result)
//           ? result
//           : JSON.parse(result); // Ensure parsedArbitrators is an array
//         console.log(parsedUnassignedLots);
//         setUnassignedLots(parsedUnassignedLots);
//       } catch (error) {
//         setError1(error.message);
//       }
//     };

//     fetchArbitrators();
//   }, []);

//   const handleButtonClick = async (lot) => {
//     try {
//       setLoading(true); // Start loading
//       const response = await fetch(
//         "http://arb.resolutionexperts.in/api/Uploaddata?Lot_no=1-Axis-Vehical"
//       );
//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }
//       const result = await response.json();
//       const parsedUnassignedData = JSON.parse(result); // Ensure parsedArbitrators is an array
//       console.log(parsedUnassignedData);
//       setUnassignedData(parsedUnassignedData);
//     } catch (error) {
//       setError2(error.message);
//     } finally {
//       setLoading(false); // Stop loading
//     }
//   };

//   return (
//     <>
//       {loading && <p>Loading...</p>}
//       <table className="table table-striped">
//         <thead>
//           <tr>
//             <th scope="col">Lots</th>
//             <th scope="col">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {unassignedLots.map((item, index) => (
//             <tr key={index}>
//               <td>{item.Lots}</td>
//               <td>
//                 <button
//                   className="btn btn-primary"
//                   onClick={() => handleButtonClick(item.Lots)}
//                   disabled={loading}
//                 >
//                  {unassignedData.length == [] ? <span>Get Data</span>:<span>Assign Arbitrator</span>}
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </>
//   );
// };

// export default Trial;
