import React from 'react'

const ItDashboardMain = () => {
  return (
    <div>
      <h1> IT Dashboard Main Page</h1>
    </div>
  )
}

export default ItDashboardMain
