import React from 'react'

const Section17Order = () => {
  return (
    <div>
      <h1>Section 17 Order Page</h1>
    </div>
  )
}

export default Section17Order
