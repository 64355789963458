export const headers = [
    {
      name: "SR_NO",
      validations: [
        { type: "notEmpty", message: "SR_NO cannot be empty" },
        { type: "integer", message: "SR_NO must be an integer" },
      ],
    },
    {
      name: "Lot_No",
      validations: [{ type: "notEmpty", message: "Lot_No cannot be empty" }],
    },
    {
      name: "ACC_NO",
      validations: [{ type: "notEmpty", message: "ACC_NO cannot be empty" }],
    },
    {
      name: "CUST_ID",
      validations: [
        { type: "notEmpty", message: "CUST_ID cannot be empty" },
        { type: "integer", message: "CUST_ID must be an integer" },
      ],
    },
    {
      name: "CUST_NAME",
      validations: [
        { type: "notEmpty", message: "CUST_NAME cannot be empty" },
        { type: "textOnly", message: "CUST_NAME must be an text only" },
      ],
    },
    {
      name: "E_MAIL_ID",
      validations: [
        { type: "notEmpty", message: "REFERENCE_NO cannot be empty" },
      ],
    },
    {
      name: "REFERENCE_NO",
      validations: [
        { type: "notEmpty", message: "REFERENCE_NO cannot be empty" },
      ],
    },
    {
      name: "PRODUCT",
      validations: [{ type: "notEmpty", message: "PRODUCT cannot be empty" }],
    },
    {
      name: "REGISTRATION_NO",
      validations: [
        { type: "notEmpty", message: "REGISTRATION_NO cannot be empty" },
      ],
    },
    {
      name: "ENGINE_NUMBER",
      validations: [
        { type: "notEmpty", message: "ENGINE_NUMBER cannot be empty" },
      ],
    },
    {
      name: "CHASSIS_NUMBER",
      validations: [
        { type: "notEmpty", message: "CHASSIS_NUMBER cannot be empty" },
      ],
    },
    {
      name: "MODEL1",
      validations: [{ type: "notEmpty", message: "MODEL1 cannot be empty" }],
    },
    {
      name: "MODEL2",
    },
    {
      name: "MANUFACTURER",
      validations: [
        { type: "notEmpty", message: "MANUFACTURER cannot be empty" },
      ],
    },
    {
      name: "DEALER",
      validations: [{ type: "notEmpty", message: "DEALER cannot be empty" }],
    },
    {
      name: "INTEREST_RATE",
      validations: [
        { type: "notEmpty", message: "INTEREST_RATE cannot be empty" },
        { type: "integer", message: "INTEREST_RATE must be an integer" },
      ],
    },
    {
      name: "DISBURSEMENT_AMOUNT",
      validations: [
        { type: "notEmpty", message: "DISBURSEMENT_AMOUNT cannot be empty" },
        { type: "integer", message: "DISBURSEMENT_AMOUNT must be an integer" },
      ],
    },
    {
      name: "DISB_AMOUNT_IN_WORDS",
      validations: [
        { type: "notEmpty", message: "DISB_AMOUNT_IN_WORDS cannot be empty" },
        {
          type: "textOnly",
          message: "DISB_AMOUNT_IN_WORDS must be an text only",
        },
      ],
    },
    {
      name: "DISBURSEMENT_DATE",
      validations: [
        { type: "notEmpty", message: "DISBURSEMENT_DATE cannot be empty" },
        {
          type: "dateOnly",
          message: "DISBURSEMENT_DATE must be an date in dd-mm-yyyy format only",
        },
      ],
    },
    {
      name: "TENURE",
      validations: [
        { type: "notEmpty", message: "TENURE cannot be empty" },
        { type: "integer", message: "TENURE must be an integer" },
      ],
    },
    {
      name: "EMI_AMT",
      validations: [
        { type: "notEmpty", message: "EMI_AMT cannot be empty" },
        { type: "integer", message: "EMI_AMT must be an integer" },
      ],
    },
    {
      name: "EMI_START_DATE",
      validations: [
        { type: "notEmpty", message: "EMI_START_DATE cannot be empty" },
        {
          type: "dateOnly",
          message: "EMI_START_DATE must be an date in dd-mm-yyyy format only",
        },
      ],
    },
    {
      name: "ORGANIZATION_NAME",
    },
    {
      name: "FORCLOSER_AMT_ROUNDUP",
      validations: [
        { type: "notEmpty", message: "FORCLOSER_AMT_ROUNDUP cannot be empty" },
        { type: "integer", message: "FORCLOSER_AMT_ROUNDUP must be an integer" },
      ],
    },
    {
      name: "FORCLOSER_AMT_IN_WORDS",
      validations: [
        { type: "notEmpty", message: "FORCLOSER_AMT_IN_WORDS cannot be empty" },
        {
          type: "textOnly",
          message: "FORCLOSER_AMT_IN_WORDS must be an text only",
        },
      ],
    },
    {
      name: "LOAN_START_DATE",
      validations: [
        { type: "notEmpty", message: "LOAN_START_DATE cannot be empty" },
        {
          type: "dateOnly",
          message: "LOAN_START_DATE must be an date in dd-mm-yyyy format only",
        },
      ],
    },
    {
      name: "LRN_date",
      validations: [
        { type: "notEmpty", message: "LOAN_START_DATE cannot be empty" },
        {
          type: "dateOnly",
          message: "LOAN_START_DATE must be an date in dd-mm-yyyy format only",
        },
      ],
    },
    {
      name: "Mobile_no1",
      validations: [
        { type: "notEmpty", message: "Mobile_no1 cannot be empty" },
        { type: "integer", message: "Mobile_no1 must be an integer" },
      ],
    },
    {
      name: "Mobile_no2",
      validations: [
        { type: "integer", message: "Mobile_no1 must be an integer" },
      ],
    },
    {
      name: "Mobile_no3",
      validations: [
        { type: "integer", message: "Mobile_no1 must be an integer" },
      ],
    },
    {
      name: "WORK_FINAL_CITY",
    },
    {
      name: "WORK_CONTACT",
    },
    {
      name: "WORK_MOBILE_2",
    },
    {
      name: "WORK_MOBILE_3",
    },
    {
      name: "FATHER_NAME",
    },
    {
      name: "BRANCH_RAC_NAME",
      validations: [
        { type: "notEmpty", message: "BRANCH_RAC_NAME cannot be empty" },
        { type: "textOnly", message: "BRANCH_RAC_NAME must be an text only" },
      ],
    },
    {
      name: "FINAL_CITY",
    },
    {
      name: "STATE",
      validations: [
        { type: "notEmpty", message: "STATE cannot be empty" },
        { type: "textOnly", message: "STATE must be an text only" },
      ],
    },
    {
      name: "CO_BORROWER_NAME",
    },
    {
      name: "CO_BORROWER_CURRENT_ADDRESS",
    },
    {
      name: "BORROWER_CURRENT_ADDRESS",
      validations: [
        { type: "notEmpty", message: "BORROWER_CURRENT_ADDRESS cannot be empty" },
      ],
    },
    {
      name: "And_Also_At",
    },
    {
      name: "BORROWER_PERMANENT_ADDRESS",
    },
    {
      name: "And_Also_At_2",
    },
    {
      name: "BORROWER_WORK_ADDRESS",
    },
  ];

