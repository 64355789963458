import React from 'react'

const LawyerDashboardMain = () => {
  return (
    <div>
      <h2> Main Lawyer Dashboard Page</h2>
    </div>
  )
}

export default LawyerDashboardMain
