import React, { useState } from "react";
import "./pages.css";
import Sidebar from "../Componants/Sidebar";
import Topbar from "../Componants/Topbar";
import { Button, Form } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { useAuth } from '../Componants/AuthProvider';
import { Navigate } from 'react-router-dom';

const BnkDashboard = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("Dashboard");
  const [isOpen, setIsOpen] = useState(true);
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  const menuItems = [
    {
      name: "Dashboard",
      icon: "home", // Material Icon name
      link: "/bankdashboard",
    },
    // {
    //   name: "Case Tracking",
    //   icon: "query_stats",
    //   link: "/bankdashboard/casetracking",
    // },
    {
        name: 'Case Tracking',
        icon: 'query_stats',
        submenu: [
            { name: 'Lotwise', link: '/bankdashboard/casetracking/lotwise', icon: 'person' },
            { name: 'Casewise', link: '/bankdashboard/casetracking/casewise', icon: 'security' },
        ]
    }
  ];

  const handleMenuItemClick = (name) => {
    setSelectedMenuItem(name);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="main-div">
       <Topbar isOpen={isOpen}/>
            <Sidebar menuItems={menuItems} user={user.User_name} onMenuItemClick={handleMenuItemClick} isOpen={isOpen} toggleSidebar={toggleSidebar}/>       
            <div className= {`${isOpen?"":"sideCollapse"} mainn-content p-3`}>           
            <Outlet />
            </div>
    </div>
  );
};

export default BnkDashboard;
