// src/App.js
import React, { useState, useEffect } from "react";
import { scrollSpy } from "react-scroll";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CustomNavbar from "./Componants/Navbar";
import Home from "./Componants/Home";
import About from "./Componants/About";
import ContactUs from "./Componants/ContactUs";
import LoginForm from "./Componants/LoginForm";
import Dashboard from "./Componants/Dashboard";
import HeroSection from "./Componants/HeroSection";
import OurExpertise from "./Componants/OurExpertise";
import CompanyInfo from "./Componants/CompanyInfo";
import ClientInfo from "./Componants/ClientInfo";
import VideoBack from "./Componants/Videoback";
import LRN from "./Arbitration/LRN/LRN";
import StartUpLogo from "./Componants/StartUpLogo";
import BnkDashboard from "./pages/BnkDashboard";
import InstDashboard from "./pages/InstDashboard";
import ItDashboard from "./pages/ItDashboard";
import LawyerDashboard from "./pages/LawyerDashboard";
import AddArbInfo from "./Componants/AddArbInfo";
import UploadExcel from "./Componants/UploadExcel";
import LawyerDashboardMain from "./Componants/LawyerDashboardMain";
import UploadSOC from "./Componants/UploadSOC";
import Section17Application from "./Componants/Section17Application";
import ArbDashboard from "./pages/ArbDashboard";
import Arbitrators from "./Componants/Arbitrators";
import Banks from "./Componants/Banks";
import AcceptanceLetter from "./Componants/AcceptanceLetter";
import TrialAcceptanceLetter from "./Componants/TrialAcceptanceLetter";
import Section17Order from "./Componants/Section17Order";
import FinalHearingNotice from "./Componants/FinalHearingNotice";
import CaseTracking from "./Componants/CaseTracking";
import ArbDashboardMain from "./Componants/ArbDashboardMain";
import ItDashboardMain from "./Componants/ItDashboardMain";
import InstDashboardMain from "./Componants/InstDashboardMain";
import AssignArbitrator from "./Componants/AssignArbitrator";
import ReferenceDraft from "./Componants/ReferenceDraft";
import BankDashboardMain from "./Componants/BankDashboardMain";
import BankInfo from "./Componants/BankInfo";
import Trial from "./Componants/Trial";
import VirtualMeeting from "./Componants/VirtualMeeting";
import ReferenceDraftServices from "./Componants/ReferenceDraftServices";
import GenerateDraft from "./Componants/GenerateDraft";
import AcceptanceLetterService from "./Componants/AcceptanceLetterService";
import ReferenceDraftReports from "./Componants/ReferenceDraftReports";
import AcceptanceLetterReports from "./Componants/AcceptanceLetterReports";
import CaseTrackingLotWise from "./Componants/CaseTrackingLotWise";
import CaseTrackingCaseWise from "./Componants/CaseTrackingCaseWise";
import { Modal, Box, Typography, Button, Zoom } from "@mui/material";
import "./App.css"; // Import the CSS file
import { RxCookie } from "react-icons/rx";
import Cookie from "./img/cookie.png";

const App = () => {
  // useEffect(() => {
  //     scrollSpy.update();
  // }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cookiesConsentOpen, setCookiesConsentOpen] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem("consentGiven");
    if (!consentGiven) {
      setIsModalOpen(true); // Open the main disclaimer modal if consent hasn't been given
    }
  }, []);

  const handleAccept = () => {
    setIsModalOpen(false);
    localStorage.setItem("consentGiven", "true");
    setCookiesConsentOpen(true); // Open the cookies consent modal after accepting the disclaimer
  };

  const handleCookiesConsentClose = () => {
    setCookiesConsentOpen(false);
    localStorage.setItem("cookiesConsent", "true"); // Store cookies consent in local storage
  };
  const handleCookiesConsentCancel = () => {
    setCookiesConsentOpen(false);
    // localStorage.setItem("cookiesConsent", "true"); // Store cookies consent in local storage
  };

  return (
    <div>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/" element={<VideoBack />} />
        <Route path="/bankdashboard" element={<BnkDashboard />}>
          <Route index element={<BankDashboardMain />} />
          <Route path="dashboard" element={<BankDashboardMain />} />
          <Route path="dashboard" element={<BnkDashboard />} />
          <Route path="casetracking">
            <Route path="lotwise" element={<CaseTrackingLotWise />} />
            <Route path="casewise" element={<CaseTrackingCaseWise />} />
          </Route>
        </Route>

        <Route path="/instdashboard" element={<InstDashboard />}>
          <Route index element={<InstDashboardMain />} />
          <Route path="dashboard" element={<InstDashboardMain />} />
          <Route path="uploadalot" element={<UploadExcel />} />
          <Route path="assignarb" element={<AssignArbitrator />} />
          <Route path="referencedraft">
            <Route path="generate-rd" element={<GenerateDraft />} />
            <Route path="services" element={<ReferenceDraftServices />} />
            <Route path="reports" element={<ReferenceDraftReports />} />
          </Route>
        </Route>

        <Route path="/itdashboard" element={<ItDashboard />}>
          <Route index element={<ItDashboardMain />} />
          <Route path="dashboard" element={<ItDashboardMain />} />
          <Route path="arbitrators" element={<Arbitrators />} />
          <Route path="bankinfo" element={<BankInfo />} />
          <Route path="trial" element={<Trial />} />
        </Route>

        <Route path="/arbdashboard" element={<ArbDashboard />}>
          <Route index element={<ArbDashboardMain />} />
          <Route path="dashboard" element={<ArbDashboardMain />} />
          <Route path="acceptletter" element={<AcceptanceLetter />} />
          <Route path="acceptletter">
            <Route path="generate_al" element={<AcceptanceLetter />} />
            <Route path="services" element={<AcceptanceLetterService />} />
            <Route path="reports" element={<AcceptanceLetterReports />} />
          </Route>
          <Route path="section17order" element={<Section17Order />} />
          <Route path="finalhearingnotice" element={<FinalHearingNotice />} />
          <Route path="virtualmeeting" element={<VirtualMeeting />} />
        </Route>

        <Route path="/lawyerdashboard" element={<LawyerDashboard />}>
          <Route index element={<LawyerDashboardMain />} />
          <Route path="dashboard" element={<LawyerDashboardMain />} />
          <Route path="uploadsoc" element={<UploadSOC />} />
          <Route
            path="section17application"
            element={<Section17Application />}
          />
        </Route>
      </Routes>

      {/* <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="custom-modal">
          <Typography id="modal-title" variant="h6" component="h2">
            <h2>
              Welcome to <span className="text-info fw-bold"> Resolution</span>
            </h2>
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            <p>
              Before you proceed, we kindly request you to read and understand
              our 
               <a href="https://jupitice.com/terms-of-services.php" className="text-decoration-none">
                 Terms of Service
              </a>
              , including our{" "}
              <a href="https://jupitice.com/pdfs/terms/terms-of-use.pdf" className="text-decoration-none">
                Site Terms of Use,
              </a>
              <a href="https://jupitice.com/pdfs/terms/privacy-policy.pdf" className="text-decoration-none">
                Privacy Policy,
              </a>
              <a href="https://jupitice.com/pdfs/terms/cookie-policy.pdf" className="text-decoration-none">
                Cookie Policy,
              </a>{" "}
              and
              <a href="https://jupitice.com/pdfs/terms/mark-use-guidelines.pdf" className="text-decoration-none">
                Mark Use Guidelines.
              </a>
              By using our website, you acknowledge that you have read,
              understood, and agree to be bound by these{" "}
              <a href="https://jupitice.com/terms-of-services.php" className="text-decoration-none">
                Terms of Service
              </a>
              . By accessing and using our website, you agree to be bound and
              comply with these terms. If you do not agree with any part of
              these terms, please refrain from using our services and website.
              We reserve the right to update, modify, or replace any part of
              these terms without prior notice. It is your responsibility to
              check this page periodically for changes. Additionally, please
              review the specific terms associated with the products and
              solutions offered on our website. By accessing and using our
              website, including our products and solutions, you agree to comply
              with these terms. We offer a range of products and solutions on
              our website, and the usage of these is subject to specific terms
              outlined in conjunction with each product or solution. It is your
              responsibility to review and adhere to these terms. By continuing
              to use our website, you agree to these
              <a href="https://jupitice.com/terms-of-services.php" className="text-decoration-none">
                Terms of Service.
              </a>
              If you have any questions or concerns, please reach out to us for
              assistance.
            </p>
          </Typography>
          <Box sx={{ mt: 3, textAlign: "center" }}>
  
            <Button variant="contained" onClick={handleAccept}>
              Accept
            </Button>
          </Box>
        </Box>
      </Modal> */}

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {/* <Zoom in={isModalOpen} timeout={{ enter: 300, exit: 200 }}> */}
          <Box className="custom-modal">
            <Typography id="modal-title" variant="h6" component="h2">
              <h2>
                Welcome to <span className="text-info fw-bold">Resolution</span>
              </h2>
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              <p>
                Before you proceed, we kindly request you to read and understand
                our{" "}
                <a
                  href="#"
                  className="text-decoration-none"
                >
                  Terms of Service
                </a>
                , including our{" "}
                <a
                  href="#"
                  className="text-decoration-none"
                >
                  Site Terms of Use,
                </a>
                <a
                  href="#"
                  className="text-decoration-none"
                >
                  Privacy Policy,
                </a>
                <a
                  href="#"
                  className="text-decoration-none"
                >
                  Cookie Policy,
                </a>{" "}
                and{" "}
                <a
                  href="#"
                  className="text-decoration-none"
                >
                  Mark Use Guidelines.
                </a>
                By using our website, you acknowledge that you have read,
                understood, and agree to be bound by these{" "}
                <a
                  href="#"
                  className="text-decoration-none"
                >
                  Terms of Service
                </a>
                . If you do not agree with any part of these terms, please
                refrain from using our services and website.
              </p>
            </Typography>
            <Box sx={{ mt: 3, textAlign: "center" }}>
              <Button variant="contained" onClick={handleAccept}>
                Accept
              </Button>
            </Box>
          </Box>
        {/* </Zoom> */}
      </Modal>

      {/* Cookies Consent Modal */}
      <Modal
        open={cookiesConsentOpen}
        onClose={handleCookiesConsentClose}
        aria-labelledby="cookies-modal-title"
        aria-describedby="cookies-modal-description"
      >
        {/* <Zoom in={isModalOpen} timeout={{ enter: 300, exit: 200 }}> */}
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
            className="custom-modal text-center"
          >
            <Typography
              id="cookies-modal-title"
              variant="h6"
              component="h2"
              className=""
            >
              <img src={Cookie} className="img-fluid rounded me-3" />
              {/* <RxCookie  className="me-3"/> */}
              Cookies Consent
            </Typography>
            <Typography
              id="cookies-modal-description"
              sx={{ mt: 2 }}
              className="text-start"
            >
              We use cookies to improve your experience. Please accept our
              cookies policy.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCookiesConsentCancel}
              sx={{ mt: 3 }}
              className="me-3"
            >
              Reject Cookies
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCookiesConsentClose}
              sx={{ mt: 3 }}
              className=""
            >
              Accept Cookies
            </Button>
          </Box>
        {/* </Zoom> */}
      </Modal>
    </div>
  );
};

export default App;
