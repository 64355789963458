import React, { useState } from "react";
import { Button, Form, Modal, Pagination } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { FaRegMessage } from "react-icons/fa6";
import { FaPrint } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";

const AcceptanceLetterReports = () => {
  const [selectedLotNo, setSelectedLotNo] = useState(null);

  // For Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLotNoChange = (e) => {
    const selectedID = e.target.value;
    setSelectedLotNo(selectedID);
  };

  console.log(selectedLotNo);

  // to handle the services
  const handleServices = () => {
    setShow(true);
  };

  // To print the Reference Draft
  const handlePrint = () => {
    alert("printing");
    toast.success("Printing", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  };

  // To Mail The Reference Draft
  const handleMail = async () => {
    // const dataForMail = data.map((item) => ({
    //   Ref_no: item.REFERENCE_NO,
    //   E_mail_id: item.E_MAIL_ID,
    //   Service_id: "1",
    // }));
    // console.log(dataForMail);
    // try {
    //   const response = await fetch(
    //     "http://192.168.43.135:8000/api/Services/Mail",
    //     // "http://localhost:50180/api/assignArb",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ case: dataForMail }),
    //     }
    //   );
    //   if (!response.ok) {
    //     const errorText = await response.text();
    //     throw new Error(
    //       `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
    //     );
    //   }
    //   const result = await response.json(); // Process the response
    //   console.log("Upload response:", result);
    //   // alert("Mail Successful")
    //   toast.success("Mail Sent Successfully", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     theme: "colored",
    //   });
    // } catch (error) {
    //   console.error("Error uploading data:", error);
    //   // alert(`Error uploading data: ${error.message}`);
    // } finally {
    //   setLoading(false);
    // }
  };

  // To Whatsapp the Reference Draft
  const handleWhatsapp = () => {
    // setLoading(true);
    // dataForWhatsapp.map((item) => {
    //   fetch(
    //     `https://whatsapp.xpressdigital.online/api/send?number=91${item.MOBILE_NO_1}&type=media&message=test+message&media_url=http://arb.resolutionexperts.in/Content/Cases/Ref/${item.REFERENCE_NO}.pdf&filename=${item.REFERENCE_NO}.pdf&instance_id=66A79167753A3&access_token=66a791382d52c`
    //   )
    //     .then((response) => {
    //       if (!response.ok) {
    //         throw new Error(
    //           "Network response was not ok " + response.statusText
    //         );
    //       }
    //       return response.json(); // or response.text() or response.blob(), etc.
    //     })
    //     .then((data) => {
    //       console.log(data.status);
    //       // Handle the data here
    //       if (data.status == "success") {
    //         toast.success("WhatsApp Message Sent Successfully", {
    //           position: toast.POSITION.BOTTOM_RIGHT,
    //           theme: "colored",
    //         });
    //         setStatus(true);
    //         setLoading(true);
    //       }
    //       setWhatsappStatus(data);
    //     })
    //     .catch((error) => {
    //       console.error("There was a problem with the fetch operation:", error);
    //     });
    //   //   .finally {
    //   //   setLoading(false);
    //   // };
    // });
  };

  // To text message the reference DRAFT
  const handleMessage = () => {
    toast.success("Message Sent Successfully!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "colored",
    });
  };

  return (
    <div className="container">
      <div className="row my-3">
        <div className="col-md-6">
          <h4>Services For Acceptance Letter</h4>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <Form.Control
            type="number"
            className="custom_input"
            placeholder="Enter Lot No"
            onChange={handleLotNoChange}
          />
        </div>
        <div className="col-md-2">
          <Button
            className="btn btn-primary getDataBtn"
            onClick={handleServices}
          >
            Services
          </Button>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="text-center">
          <Modal.Title>Send Reference Draft</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center d-flex justify-content-center my-3">
          <div
            className="modalIcon shadow-lg pt-3 rounded mx-3"
            onClick={handlePrint}
          >
            <FaPrint className="fs-2 mx-4 text-info" />
            <p className="mt-3">REPD</p>
          </div>
          <div
            className="modalIcon shadow-lg pt-3 rounded mx-3"
            onClick={handleMail}
          >
            <CiMail className="fs-2 mx-4 text-danger" />
            <p className="mt-3">Mail</p>
          </div>
          <div className="modalIcon shadow-lg pt-3 rounded mx-3">
            <FaWhatsapp
              className="fs-2 mx-4 text-success shadow-xl"
              onClick={handleWhatsapp}
            />
            <p className="mt-3">Whats App</p>
          </div>
          <div className="modalIcon shadow-lg pt-3 rounded mx-3">
            <FaRegMessage
              className="fs-2 mx-4 text-secondary shadow-xl"
              onClick={handleMessage}
            />
            <p className="mt-3">Message</p>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default AcceptanceLetterReports;
