import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { ListGroup, Collapse } from 'react-bootstrap';
import './Sidebar.css';

const Sidebar = ({ menuItems, user, onMenuItemClick, isOpen, toggleSidebar }) => {
    const location = useLocation();
    const [openMenus, setOpenMenus] = useState({});
    const [activeItem, setActiveItem] = useState('');

    // Update sidebar state based on screen size

    useEffect(() => {
        const currentPath = location.pathname;

        // Find the active item based on the current path
        menuItems.forEach((item) => {
            if (item.link === currentPath) {
                setActiveItem(item.name);
            }
            if (item.submenu) {
                item.submenu.forEach((subitem) => {
                    if (subitem.link === currentPath) {
                        setActiveItem(subitem.name);
                    }
                });
            }
        });
    }, [location.pathname, menuItems]);

    const toggleMenu = (index) => {
        setOpenMenus((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleMenuItemClick = (item) => {
        setActiveItem(item.name);
        onMenuItemClick(item.name);
    };

    return (
        <div className={`${isOpen ? "" : "sideCollapse"} sidebar min-vh-100`} style={{ fontFamily: 'Roboto, sans-serif' }}>
            <h2 className={`sidebar-h2 text-center pt-2`}>{isOpen ? user : ""}</h2>
            <button className="toggle-button" onClick={toggleSidebar}>
                <div></div>
                <div></div>
                <div></div>
            </button>

            <div>
                <ListGroup className={`cust-bg mt-3 `}>
                    {menuItems.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.submenu ? (
                                <ListGroup.Item
                                    className={`cust-item d-flex align-items-center ${activeItem === item.name ? 'active' : ''}`}
                                    onClick={() => {
                                        toggleMenu(index);
                                        handleMenuItemClick(item);
                                    }}
                                >
                                    <span className="material-icons pe-3 ">{item.icon}</span>
                                    <span className={`sidebar-option `}>{item.name} &#9660;</span>
                                </ListGroup.Item>
                            ) : (
                                <ListGroup.Item
                                    className={`cust-item d-flex align-items-center ${activeItem === item.name ? 'active' : ''}`}
                                    onClick={() => handleMenuItemClick(item)}
                                >
                                    <Link className='text-decoration-none cust-link d-flex align-items-center' to={item.link}>
                                        <span className="material-icons pe-3">{item.icon}</span>
                                        <span className='sidebar-option'>{item.name}</span>
                                    </Link>
                                </ListGroup.Item>
                            )}
                            {item.submenu && (
                                <Collapse in={openMenus[index]}>
                                    <ListGroup className="submenu">
                                        {item.submenu.map((subitem, subindex) => (
                                            <ListGroup.Item
                                                className={`cust-item ${activeItem === subitem.name ? 'active' : ''}`}
                                                key={subindex}
                                                onClick={() => handleMenuItemClick(subitem)}
                                            >
                                                <Link className='text-decoration-none cust-link d-flex align-items-center ms-2' to={subitem.link}>
                                                    <span className="material-icons pe-3">{subitem.icon}</span>
                                                    <span className='sidebar-option'>{subitem.name}</span>
                                                </Link>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Collapse>
                            )}
                        </React.Fragment>
                    ))}
                </ListGroup>
            </div>
        </div>
    );
};

Sidebar.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            link: PropTypes.string,
            submenu: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    link: PropTypes.string.isRequired,
                    icon: PropTypes.string.isRequired
                })
            )
        })
    ).isRequired,
    onMenuItemClick: PropTypes.func.isRequired,
    user: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
};

export default Sidebar;





















// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import { ListGroup, Collapse } from 'react-bootstrap';
// import './Sidebar.css';

// const Sidebar = ({ menuItems, onMenuItemClick }) => {
//     const [openMenus, setOpenMenus] = useState({});
//     const [activeItem, setActiveItem] = useState('');

//     const toggleMenu = (index) => {
//         setOpenMenus((prevState) => ({
//             ...prevState,
//             [index]: !prevState[index]
//         }));
//     };

//     const handleMenuItemClick = (item) => {
//         setActiveItem(item.name);
//         onMenuItemClick(item.name);
//     };

//     return (
//         <div className="sidebar min-vh-100" style={{ fontFamily: 'Roboto, sans-serif' }}>
//             <div>
//                 <h2 className='sidebar-h2 text-center pt-2'>Dashboard</h2>
//                 <ListGroup className='cust-bg mt-3 roboto-regular'>
//                     {menuItems.map((item, index) => (
//                         <React.Fragment key={index}>
//                             {item.submenu ? (
//                                 <ListGroup.Item
//                                     className={`cust-item d-flex align-items-center ${activeItem === item.name ? 'active' : ''}`}
//                                     onClick={() => {
//                                         toggleMenu(index);
//                                         handleMenuItemClick(item);
//                                     }}
//                                 >
//                                     <span className="material-icons ps-2 pe-3 ">{item.icon}</span>
//                                     <span>{item.name}</span>
//                                 </ListGroup.Item>
//                             ) : (
//                                 <ListGroup.Item
//                                     className={`cust-item d-flex align-items-center ${activeItem === item.name ? 'active' : ''}`}
//                                     onClick={() => handleMenuItemClick(item)}
//                                 >
//                                     <Link className='text-decoration-none cust-link d-flex align-items-center' to={item.link}>
//                                         <span className="material-icons ps-2 pe-3">{item.icon}</span>
//                                         <span>{item.name}</span>
//                                     </Link>
//                                 </ListGroup.Item>
//                             )}
//                             {item.submenu && (
//                                 <Collapse in={openMenus[index]}>
//                                     <ListGroup className="submenu">
//                                         {item.submenu.map((subitem, subindex) => (
//                                             <ListGroup.Item
//                                                 className={`cust-item ${activeItem === subitem.name ? 'active' : ''}`}
//                                                 key={subindex}
//                                                 onClick={() => handleMenuItemClick(subitem)}
//                                             >
//                                                 <Link className='text-decoration-none cust-link d-flex align-items-center ms-4' to={subitem.link}>
//                                                     <span className="material-icons ps-2 pe-3">{subitem.icon}</span>
//                                                     <span>{subitem.name}</span>
//                                                 </Link>
//                                             </ListGroup.Item>
//                                         ))}
//                                     </ListGroup>
//                                 </Collapse>
//                             )}
//                         </React.Fragment>
//                     ))}
//                 </ListGroup>
//             </div>
//             <div className="bottom-div">
//                 <ListGroup className='cust-bg'>
//                     <ListGroup.Item className='cust-item d-flex align-items-end mb-2'>
//                         <Link className='text-decoration-none cust-link d-flex align-items-center' to="/settings">
//                             <span className="material-icons ps-2 pe-3">settings</span>
//                             <span>Settings</span>
//                         </Link>
//                     </ListGroup.Item>
//                 </ListGroup>
//             </div>
//         </div>
//     );
// };

// Sidebar.propTypes = {
//     menuItems: PropTypes.arrayOf(
//         PropTypes.shape({
//             name: PropTypes.string.isRequired,
//             icon: PropTypes.string.isRequired,
//             submenu: PropTypes.arrayOf(
//                 PropTypes.shape({
//                     name: PropTypes.string.isRequired,
//                     link: PropTypes.string.isRequired,
//                     icon: PropTypes.string.isRequired
//                 })
//             )
//         })
//     ).isRequired,
//     onMenuItemClick: PropTypes.func.isRequired,
// };

// export default Sidebar;