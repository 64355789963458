import React, { useState } from "react";
import Sidebar from "../Componants/Sidebar";
import Topbar from "../Componants/Topbar";
import { Button, Modal } from "react-bootstrap";
import Arbitrator1 from "../img/Arbitrator1.jpg";
import Arbitrator2 from "../img/Arbitrator2.jpg";
import Arbitrator3 from "../img/Arbitrator3.jpg";
import AddArbInfo from "../Componants/AddArbInfo";
import { Outlet } from "react-router-dom";
import { useAuth } from "../Componants/AuthProvider";
import { Navigate } from "react-router-dom";

const ItDashboard = () => {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedMenuItem, setSelectedMenuItem] = useState("Dashboard");

  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  const menuItems = [
    {
      name: "Dashboard",
      icon: "home", // Material Icon name
      link: "/itdashboard",
    },
    {
      name: "Arbitrators",
      icon: "gavel", // Material Icon name
      link: "/itdashboard/arbitrators",
    },
    {
      name: "Banks",
      icon: "account_balance", // Material Icon name
      link: "/itdashboard/bankinfo",
    },
    {
      name: "Trial",
      icon: "account_balance", // Material Icon name
      link: "/itdashboard/trial",
    },
  ];

  const handleMenuItemClick = (name) => {
    setSelectedMenuItem(name);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Topbar isOpen={isOpen} />
      <Sidebar
        menuItems={menuItems}
        user={user.User_name}
        onMenuItemClick={handleMenuItemClick}
        isOpen={isOpen}
        toggleSidebar={toggleSidebar}
      />
      <div className={`${isOpen ? "" : "sideCollapse"} mainn-content p-3`}>
        <Outlet />
      </div>
    </div>
  );
};

export default ItDashboard;
