import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Pagination } from "react-bootstrap";
import "./uploadexcel.css";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../Componants/AuthProvider";
import { API_BASE_URL } from "../utils/constants";
import LoadingSpinner from "./LoadingSpinner";
import Refresh from "./Refresh";

const UploadSOC = () => {
  const [data, setData] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]); //the clients Data to populate dropdown
  const [selectedProduct, setSelectedProduct] = useState([]); //to add the product from the dropdown
  const [arbitrator, setArbitrator] = useState([]); //to add the product from the dropdown
  const [whatsappStatus, setWhatsappStatus] = useState([]); //to add the product from the dropdown
  const [error, setError] = useState(null);
  const [error1, setError1] = useState(null);
  const [error2, setError2] = useState(null);
  const [selectedProductID, setSelectedProductID] = useState(null);
  const [selectedClientID, setSelectedClientID] = useState(null);
  const [selectedLotNo, setSelectedLotNo] = useState(null);
  const [selectedArbitratorID, setSelectedArbitratorID] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [date, setDate] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [status, setStatus] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [show, setShow] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [clearForm, setClearForm] = useState(false);

  const { user } = useAuth();

  // To fetch Clients DaTA
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Client`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const parsedClient = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        // console.log(parsedClient);
        setSelectedClient(parsedClient);
      } catch (error) {
        setError(error.message);
      } finally {
        // setLoading(false);
      }
    };

    fetchClients();
  }, []);

  console.log(selectedClient);

  // API Call for Product data
  useEffect(() => {
    const fetchProduct = async () => {
      // setLoading(true);
      setError(null); // Reset error state
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/products?client_id=${selectedClientID}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const parsedProducts = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        console.log(parsedProducts);
        setSelectedProduct(parsedProducts); // Set products data
      } catch (error) {
        setError1(error);
      } finally {
        setLoading1(false);
      }
    };

    fetchProduct();
  }, [selectedClientID]);

  // API Call for Arbitrator
  useEffect(() => {
    const fetchArbitrator = async () => {
      setLoading(true);
      setError(null); // Reset error state
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/arbitrator`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const parsedArbitrators = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        console.log(parsedArbitrators);
        setArbitrator(parsedArbitrators); // Set products data
      } catch (error) {
        setError1(error);
      } finally {
        setLoading(false);
      }
    };

    fetchArbitrator();
  }, []);

  console.log(arbitrator);
  console.log(user);

  // OnChange Events

  const handleSelectChange = (e) => {
    const selectedID = e.target.value;
    setSelectedClientID(selectedID);
  };

  const handleProductChange = (e) => {
    const selectedID = e.target.value;
    setSelectedProductID(selectedID);
  };

  const handleArbitratorChange = (e) => {
    const selectedID = e.target.value;
    setSelectedArbitratorID(selectedID);
  };

  const handleLotNoChange = (e) => {
    const selectedID = e.target.value;
    setSelectedLotNo(selectedID);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date); // Update state with the selected date
    // console.log(date.toISOString().split("T")[0]);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    console.log(formattedDate); // Logs "August 31, 2024"
    setDate(formattedDate);
  };

  console.log(selectedProductID);
  console.log(selectedClientID);
  console.log(selectedLotNo);
  console.log(selectedDate);

  if (loading) return <LoadingSpinner />;

  // Show Data Logic Goes here
  const handleData = async () => {
    setLoading(true); // Start loading before fetching data
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/AccData?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      const parsedData = Array.isArray(result) ? result : JSON.parse(result);
      const updatedData = parsedData.map((item) => {
        const { Acc_id, Acc_date, Arb_id, ...rest } = item;
        return {
          ...rest,
        };
      });
      console.log(updatedData);
      console.log(parsedData);
      setData(updatedData);
      setTotalPages(Math.ceil(updatedData.length / itemsPerPage));
      setShowTable(true);
      // handleStepChange(1)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(data);
  const headers = data.length > 0 ? Object.keys(data[0]) : [];
  console.log(headers);

  // To save SOC Data

  const handleSaveSOC = async () => {
    const dataToGenerateSOC = data.map((item) => ({
      Case_id: item.case_id,
      Product_id: selectedProductID,
      Client_id: selectedClientID,
      Soc_date: date,
      User: user.User_name,
    }));

    console.log(dataToGenerateSOC);
    // console.log(JSON.stringify({ case: "dataToGenerateSOC" }));
    setLoading(true); // Start loading before fetching data
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/SocSave`,
        // "http://arb.resolutionexperts.in/api/SocSave",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ case: dataToGenerateSOC }),
        }
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
        );
      }
      const result = await response.json(); // Process the response
      console.log("Upload response:", result);
      toast.success("Data Saved Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
        autoClose: 1000,
      });
      setShowSaved(true);
    } catch (error) {
      console.error("Error uploading data:", error);
      // alert(`Error uploading data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Function for generate thr SOC
  const handleGenerateSOC = async () => {
    setLoading(true); // Start loading before fetching data
    try {
      // Fetch the PDF file from the API
      const response = await fetch(
        `${API_BASE_URL}/api/SocLetter?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}`
        // `http://localhost:8000/api/SocLetter?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Convert the response to a Blob
      const pdfBlob = await response.blob();
      console.log(pdfBlob);
      // Create a URL for the Blob
      const pdfUrl1 = URL.createObjectURL(pdfBlob);
      console.log(pdfUrl);
      // Set the PDF URL to the state
      setPdfUrl(pdfUrl1);
      // setPdfUrl(pdfUrl);
      setShowPDF(true);
      // handleStepChange(2);
    } catch (error) {
      console.error("Error fetching and displaying the PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function for upload thr SOC
  const handleUploadSOC = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/SaveSocCase?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}`
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
        );
      }

      const result = await response.json(); // Process the response
      // console.log(result);
      setClearForm(true);
      // handleStepChange(3);
      // handleStepChange(4);
    } catch (error) {
      console.error("Error uploading data:", error);
      alert(`Error uploading data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // // Data to be sent for detail
  // const dataToBeSent = {
  //   Client_id: selectedClientID,
  //   Product_id: selectedProductID,
  //   Lot_No: selectedLotNo,
  //   Arbitrator_id: selectedArbitratorID,
  // };

  // to handle the services
  const handleServices = () => {
    setShow(true);
  };

  // Logic For Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPagesToShow = 5; // Maximum number of page buttons to show

  const generatePaginationItems = () => {
    const pageItems = [];
    let startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

    if (endPage - startPage + 1 < totalPagesToShow) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  return (
    <>
      <div className="container">
        {/* Top Lable */}
        <div className="row">
          {/* {!clearForm && ( */}
          <div className="col-md-6">
            <h4 className="my-3 ms-1">Generate SOC</h4>
          </div>
          {/* )} */}
          <div className="col-md-6 mt-3">
            {showTable && !showSaved && (
              <button className="custBtn" onClick={handleSaveSOC}>
                Save SOC
              </button>
            )}
            {/* {!showTable && showSaved && ( */}
            {showSaved && !showPDF && (
              // {!showPDF && (
              <button className="custBtn" onClick={handleGenerateSOC}>
                Generate SOC
              </button>
            )}
            {showPDF && (
              <button className="custBtn" onClick={handleUploadSOC}>
                Upload SOC
              </button>
            )}
            {showServices && (
              <button className="custBtn" onClick={handleServices}>
                Services
              </button>
            )}
          </div>
        </div>

        {!showTable && (
          <div className="row align-items-center justify-content-evenly">
            {/* To Select The Client Bank */}
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-6">
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleSelectChange}
                    className="custom_input"
                  >
                    <option value="" selected>
                      Choose a Bank
                    </option>
                    {selectedClient.map((item) => (
                      <option key={item.Client_id} value={item.Client_id}>
                        {item.client_name}
                      </option>
                    ))}
                  </Form.Select>
                </div>

                {/* To Select The pRoduct Of the Bank */}
                <div className="col-md-6">
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleProductChange}
                    className="custom_input"
                  >
                    <option value="" selected>
                      Choose a Product
                    </option>
                    {selectedProduct.map((item) => (
                      <option key={item.Product_id} value={item.Product_id}>
                        {item.Product_name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            </div>

            {/* To enter the Lot No */}
            <div className="col-md-2">
              <Form.Control
                type="number"
                className="custom_input"
                placeholder="Enter Lot No"
                onChange={handleLotNoChange}
              />
            </div>

            {/* To Get The arbitrator */}
            <div className="col-md-3">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                placeholderText="Select a date"
                dateFormat="MMMM d, yyyy"
                className="form-control custom_input" // Smaller size
                id="datePicker"
                style={{
                  width: "100%",
                  height: "calc(1.5em + .75rem + 2px)",
                  padding: ".375rem .75rem",
                  fontSize: "1rem",
                  borderRadius: ".25rem",
                  border: "1px solid #ced4da",
                  boxSizing: "border-box",
                }}
              />
            </div>

            {/* For Button */}
            <div className="col-md-2">
              <button className="custBtn" onClick={handleData}>
                GetData
              </button>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="table-container">
              <div className="table-wrapper">
                {data.length > 0 && !showPDF && (
                  <table className="responsive-table">
                    <thead>
                      <tr >
                        {headers.map((header) => (
                          <th key={header} className="text-center">{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            maxHeight: "50px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          className="text-center custom_fz"
                        >
                          {headers.map((header) => (
                            <td key={header} className="text-center">{item[header]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              {totalPages > 1 && (
                <Pagination className="justify-content-center">
                  <Pagination.Prev
                    onClick={() =>
                      setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))
                    }
                  />
                  {generatePaginationItems()}
                  <Pagination.Next
                    onClick={() =>
                      setCurrentPage((prev) =>
                        prev < totalPages ? prev + 1 : prev
                      )
                    }
                  />
                </Pagination>
              )}
            </div>
            {/* <div className="col-md-12 mt-3">
            <div className="table-responsive">
              {data.length > 0 && !showPDF && (
                <table className="table table-striped table-bordered table-hover mt-3 text-center">
                  <thead>
                    <tr>
                      {headers.map((header) => (
                        <th key={header}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          maxHeight: "50px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        className="text-center custom_fz"
                      >
                        {headers.map((header) => (
                          <td key={header}>{item[header]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            {totalPages > 1 && (
              <Pagination className="justify-content-center">
                <Pagination.Prev
                  onClick={() =>
                    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))
                  }
                />
                {generatePaginationItems()}
                <Pagination.Next
                  onClick={() =>
                    setCurrentPage((prev) =>
                      prev < totalPages ? prev + 1 : prev
                    )
                  }
                />
              </Pagination>
            )}
          </div> */}
          </div>
        </div>

        {/* {showPDF && !showServices && ( */}
        <div className="row">
          <div className="col-md-12 mt-3">
            {pdfUrl && (
              <iframe
                src={pdfUrl}
                style={{ width: "100%", height: "100vh" }}
                title="PDF Viewer"
              />
            )}
          </div>
        </div>
        {/* )} */}

        {clearForm && (
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center ">
              <Refresh
                message="SOC uploaded Successfully"
                redirectPath="/instdashboard" // Specify the redirect path
              />
            </div>
          </div>
        )}


        <ToastContainer />
      </div>
    </>
  );
};

export default UploadSOC;
