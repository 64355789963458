import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const AddArbInfo = () => {
  const [arbInfo, setArbInfo] = useState([]);

  const [formData, setFormData] = useState({
    fullName: "",
    address: "",
    pin: "",
    mobileNo: "",
    email: "",
    designation: "",
    qualification: "",
    yearOfPassing: "",
    signature: null,
    stamp: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setArbInfo((prevData) => {
      const updatedData = [...prevData, formData];
      // Example: Save data to localStorage
      localStorage.setItem("arbInfo", JSON.stringify(updatedData));
      return updatedData;
      console.log(updatedData);
    });

    setFormData({
      fullName: "",
      address: "",
      pin: "",
      mobileNo: "",
      email: "",
      designation: "",
      qualification: "",
      yearOfPassing: "",
      signature: null,
      stamp: null,
    });

    // Example: Send data to server
    // fetch('/api/saveFormData', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(formData),
    // }).then(response => response.json()).then(data => {
    //     console.log('Success:', data);
    // }).catch((error) => {
    //     console.error('Error:', error);
    // });
  };

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= currentYear - 50; year--) {
      years.push(year);
    }
    return years;
  };

  return (
    <div>
      <div className="container-fluid customShadow">
        <div className="row">
          <h4 className="text-center my-3"> Arbitrator Details</h4>
          <div className="col-md-10 mx-auto mt-3">
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col xs={12} sm={12}>
                  <Form.Group controlId="firstName">
                    {/* <Form.Label>Full Name</Form.Label> */}
                    <Form.Control
                      type="text"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      placeholder="Enter full name"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Group controlId="address">
                    {/* <Form.Label>Address</Form.Label> */}
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      placeholder="Enter address"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={6} sm={3} md={4}>
                  <Form.Group controlId="pin">
                    {/* <Form.Label>PIN</Form.Label> */}
                    <Form.Control
                      type="number"
                      placeholder="Enter PIN code"
                      name="pin"
                      value={formData.pin}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Form.Group controlId="mobile">
                    {/* <Form.Label>Mobile</Form.Label> */}
                    <Form.Control
                      type="number"
                      placeholder="Enter mobile number"
                      name="mobileNo"
                      value={formData.mobileNo}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Form.Group controlId="email">
                    {/* <Form.Label>Email address</Form.Label> */}
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={12} sm={6} md={4}>
                  <Form.Group controlId="designation">
                    {/* <Form.Label>Designation</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Enter designation"
                      name="designation"
                      value={formData.designation}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Form.Group controlId="qualification">
                    {/* <Form.Label>Qualification</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Enter qualification"
                      name="qualification"
                      value={formData.qualification}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} sm={3} md={4}>
                  <Form.Group controlId="yearOfPassing">
                    {/* <Form.Label>Year of Passing</Form.Label> */}
                    <Form.Select
                      aria-label="Year of Passing"
                      name="yearOfPassing"
                      value={formData.yearOfPassing}
                      onChange={handleChange}
                      required
                    >
                      <option>Select year</option>
                      {generateYears().map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={6} md={6}>
                  <Form.Group controlId="signature">
                    <Form.Label>Signature</Form.Label>
                    <Form.Control
                      type="file"
                      name="signature"
                      onChange={handleFileChange}
                      // required
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={6}>
                  <Form.Group controlId="stamp">
                    <Form.Label>Stamp</Form.Label>
                    <Form.Control
                      type="file"
                      name="stamp"
                      onChange={handleFileChange}
                      // required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} sm={12}>
                  <Form.Group controlId="firstName">
                    {/* <Form.Label>Law Firm</Form.Label> */}
                    <Form.Control
                      type="text"
                      name="lawFirm"
                      value={formData.lawFirm}
                      onChange={handleChange}
                      placeholder="Enter Law Firm Name"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="text-center mt-4">
                <Col xs={12} sm={12}>
                  <button type="submit" className="custBtn">
                    Submit
                  </button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddArbInfo;
