import React from "react";
import "./InstDashboardMain.css";
import LineChar from "./LineChar";

const InstDashboardMain = () => {
  return (
    <>
      <div className="stats">
        <div className="statsItem">
          <div className="statsTitle pregress"> 2</div>
          <div className="statsContainer">Unassigned Lots</div>
        </div>

        <div className="statsItem">
          <div className="statsTitle pending"> 200 </div>
          <div className="statsContainer">Pending Cases</div>
        </div>

        <div className="statsItem">
          <div className="statsTitle complete">500</div>
          <div className="statsContainer"> Cases Resolved this week </div>
        </div>
      </div>

      <div>
        <div className="dashboard-content">
          <div className="arb-panel">
            <div className="arb-header">
              <h4 className="pt-2">Arbitrators</h4>
            </div>
            <div className="arb-list">
              <div className="list-item">
                <p>
                  Gulabrao Awasarmol <span>185</span>
                </p>
              </div>
              <div className="list-item">
                <p>
                  Satish Tyagi <span>250</span>
                </p>
              </div>
              <div className="list-item">
                <p>
                  Kamlakar Mishra <span>110</span>
                </p>
              </div>
            </div>
          </div>

          <div className="chart-area">
            <div className="arb-header">
              <h4 className="pt-2">Case Tracker</h4>
            </div>
            <div className="chart-header mt-5">
              {/* <h2>Case Tracker</h2> */}
              <LineChar className=""/>
            </div>
          </div>

          <div className="arb-panel">
            <div className="arb-header">
              <h4 className="pt-2">Pending Cases</h4>
            </div>
            <div className="arb-list">
              <div className="list-item">
                <p>
                  Reference Drafts <span>185</span>
                </p>
              </div>
              <div className="list-item">
                <p>
                  Arbitrator Assign <span>250</span>
                </p>
              </div>
              <div className="list-item">
                <p>
                  Acceptance Letter <span>110</span>
                </p>
              </div>
              <div className="list-item">
                <p>
                  SOC <span>163</span>
                </p>
              </div>
              <div className="list-item">
                <p>
                  Sec 17 Application <span>90</span>
                </p>
              </div>
              <div className="list-item">
                <p>
                  1st Hearing <span>34</span>
                </p>
              </div>
              <div className="list-item">
                <p>
                  2nd Hearing <span>24</span>
                </p>
              </div>
              <div className="list-item">
                <p>
                  Pursis <span>353</span>
                </p>
              </div>
              <div className="list-item">
                <p>
                  Award <span>2569</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstDashboardMain;
