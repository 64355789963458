// import React from "react";
// import { useNavigate } from "react-router-dom";

// export const Refresh = ({props}) => {
//   const navigate = useNavigate();

//   setTimeout(() => {
//     console.log("Hello, World!");
//   }, 2000);

//   navigate("/home");

//   return <div>
//     {/* let message  = prpos.message; */}
//     <h1>{props.message}</h1>
//   </div>;
// };
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Refresh = ({ message, redirectPath }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      // Redirect to the provided path after 2 seconds
      navigate(redirectPath);
    }, 3000); // 3 seconds delay before redirection

    return () => clearTimeout(timer); // Clear the timer when component unmounts
  }, [navigate, redirectPath]);

  return (
    <div style={styles.container}>
      <p style={styles.message}>{message}</p> {/* Display the passed message */}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f4f4f4',
  },
  message: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
  },
};

export default Refresh;

