import React from "react";
import "./Sidebar.css";
import "./Topbar.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const CommonTopbar = ({ isOpen }) => {
  // const navigate = useNavigate()

  // const handleLogoutClick = () => {
  //   // onLogout()
  //   navigate("/")
  // }

  const { user, logout } = useAuth();

  return (
    <div>
      <div id="top-bar" className={`top-bar ${isOpen ? "" : "sideCollapse"}`}>
        <div class="TopNav">
          <div className="search-container">
            <div className="search-wrapper">
              <input
                type="text"
                className="search-bar"
                placeholder="Search..."
              />
              <span className="material-icons search-icon">search</span>
            </div>
          </div>
          <div className="rightBox justify-content-end">
            {/* <span className="material-icons me-2 text-danger icon">
              settings
            </span>
            <p className="vr mt-3 me-2"></p>
            <span className="material-icons me-2 text-danger icon">
              message
            </span>
            <p className="vr mt-3 me-2"></p> */}
            {/* <p className="text-center m-0  d-flex align-items-center">                                                                                                                 <span className="material-icons m-0 text-primary">
                                                                                                                        account_circle
                                                                                         </span>
  <span className=" ps-2">{user.User_name} </span></p> */}
            {/* <span className="material-icons me-2 text-danger icon">
              notifications
            </span> */}
            {/* <p className='vr mt-3 ms-2'></p> */}
            {/* <Button className="btn btn-danger ms-2" onClick={handleLogoutClick}>
                                                                                                                                      Logout
                                                                                                                                    </Button> */}
            <button className="button1" onClick={logout}>
              Logout
            </button>
          </div>
          <div class="col-md-1 ps-5"></div>
          <div class="col-md-1 ps-5"></div>
        </div>
      </div>
    </div>
  );
};

export default CommonTopbar;

// code by Vishal
// import React from 'react'

// export default function Topbar({user}) {
//   return (
//     <div className='topbar'>
//     <div className="topbarwrapper">
//        <div className="topLeft">
//        <span className="material-icons md-light">account_circle
//        </span>
//         <span className="logo">{user}</span>
//        </div>
//        <div className="topRight">
//            <div className="topbarIconContainer">

//            <a href='/Dashboard/ImportData'>
//            <span className="material-icons md-light">
//             upload
//            </span> Import Data
//            </a>

//            <span className="material-icons md-light">
//             notifications
//            </span>
//             <span className="topIconBadge">2</span>

//            </div>
//        </div>
//     </div>
// </div>
//   )
// }
