import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import myLogo from '../img/Resolution_Digital_Logo_Final.png'

import '../Componants/StartUpLogo.css'

function StartUpLogo() {
    const [showLogo, setShowLogo] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowLogo(false);
        }, 3000); // Adjust the time (in milliseconds) you want the logo to be visible

        return () => clearTimeout(timeout);
    }, []);


    return (
        <div>
            <CSSTransition in={showLogo} timeout={1000} classNames="logo-fade" unmountOnExit>
                <div className="logo-container">
                    {/* Your logo content goes here */}
                    <img src={myLogo} alt="Logo" />
                </div>
            </CSSTransition>



        </div>
    )
}

export default StartUpLogo