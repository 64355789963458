import React, { useEffect } from "react";
import { useState } from "react";
import { Pagination } from "react-bootstrap";
import LoadingSpinner from "./LoadingSpinner";
import { API_BASE_URL } from "../utils/constants";
import { toast, ToastContainer } from "react-toastify";
import { FaWhatsapp } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaMessage } from "react-icons/fa6";

const ReferenceDraftServices = () => {
  const [notServedLots, setNotServedLots] = useState([]);
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState(false);
  const [waDone, setWaDone] = useState(false);
  const [mailDone, setMailDone] = useState(false);
  const [smsDone, setSMSDone] = useState(false);
  const [loading, setLoading] = useState(false);

  // notServed Lots Code
  useEffect(() => {
    const fetchNotServedLots = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/notServed?s_id=1`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const parsedNotServedLots = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        console.log(parsedNotServedLots);
        setNotServedLots(parsedNotServedLots);
      } catch (error) {
        // setError1(error.message);
      }
    };

    fetchNotServedLots();
  }, []);

  if (loading) return <LoadingSpinner />;

  const handleData = async (lot, arb_id) => {
    console.log(lot);
    setLoading(true); // Start loading
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/notServed?s_id=1&Lot_no=${lot}&arb_id=${arb_id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      const parsedUnassignedData = JSON.parse(result); // Ensure parsedArbitrators is an array
      console.log(parsedUnassignedData);
      const updatedData = parsedUnassignedData.map((item, index) => {
        const { UPLODED_DATE, SR_No, Case_id, LOT_NO, ...rest } = item; // Remove unwanted fields
        return {
          Serial_No: index + 1, // Add a sequential serial number
          ...rest,
          Case_id,
        };
      });
      setData(updatedData);
      setShowData(true);
      setLoading(false); // Start loading
    } catch (error) {
      // setError2(error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  console.log(data);

  const handleTransfer = () => {};

  const headers = data.length > 0 ? Object.keys(data[0]) : [];
  console.log(headers);

  // To Mail The Reference Draft
  const handleMail = async () => {
    const dataForMail = data.map((item) => ({
      Client_id: item.Client_id,
      Product_id: item.product_id,
      Ref_no: item.REFERENCE_NO,
      Service_add: item.E_MAIL_ID,
      Service_type_id: 3,
      Process_id: 1,
      Case_id: item.Case_id,
    }));
    console.log(dataForMail);
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/Services`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ case: dataForMail }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
        );
      }
      const result = await response.json(); // Process the response
      console.log("Upload response:", result);
      setMailDone(true);
      toast.success("Mail Sent Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      alert("Mail Sent Successfully");
    } catch (error) {
      console.error("Error uploading data:", error);
      // alert(`Error uploading data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // To Whatsapp the reference Draft
  const handleWhatsapp = async () => {
    console.log(data);
    const dataForWhatsapp = data.map((item) => ({
      Client_id: item.Client_id,
      Product_id: item.product_id,
      Ref_no: item.REFERENCE_NO,
      Service_add: item.MOBILE_NO_1,
      Service_type_id: 2,
      Process_id: 1,
      Case_id: item.Case_id,
    }));
    console.log(dataForWhatsapp);
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/Services`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ case: dataForWhatsapp }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
        );
      }
      const result = await response.json(); // Process the response
      console.log("Upload response:", result);
      setWaDone(true);
      toast.success("Whatsapp Message Sent Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      alert(`whatsapp Message Sent Successfully`)
    } catch (error) {
      console.error("Error uploading data:", error);
      // alert(`Error uploading data: ${error.message}`);
      // toast.danger("Mail not sent", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      //   theme: "colored",
      // });
    } finally {
      setLoading(false);
    }
  };

  const handleSMS = () => {};

  return (
    <div>
      {!showData && (
        <div className="row table-container mt-3">
          <div className="col-md-12 mx-auto table-wrapper">
            <table className="responsive-table">
              <thead className="text-center">
                <tr className="table-info">
                  <th scope="col" className="text-center">
                    Sr No
                  </th>
                  <th scope="col" className="text-center">
                    Lots
                  </th>
                  <th scope="col" className="text-center">
                    Arbitrator
                  </th>
                  <th scope="col" className="text-center">
                    Services
                  </th>
                  {/* <th scope="col" className="text-center">
                    Mail
                  </th> */}
                  {/* <th scope="col" className="text-center">
                    Message
                  </th> */}
                  <th scope="col" className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {notServedLots.map((item, index) => (
                  <tr key={item.id}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{item.Lots}</td>
                    <td className="text-center">{item.Arb_name}</td>
                    <td className="text-center">
                      <span className="">
                        <span className="p-3 border rounded-start-4">
                          {item.wa === null ? (
                            <FaWhatsapp
                              style={{ color: "Red", fontSize: "25px" }}
                            />
                          ) : (
                            <FaWhatsapp
                              style={{ color: "Green", fontSize: "25px" }}
                            />
                          )}
                        </span>
                        <span className="p-3 border">
                          {item.mail === null ? (
                            <IoMdMail
                              style={{ color: "Red", fontSize: "25px" }}
                            />
                          ) : (
                            <IoMdMail
                              style={{ color: "green", fontSize: "25px" }}
                            />
                          )}
                        </span>
                        <span className="p-3 border rounded-end-4">
                          {item.sms === null ? (
                            <FaMessage
                              style={{ color: "Red", fontSize: "25px" }}
                            />
                          ) : (
                            <FaMessage
                              style={{ color: "Green", fontSize: "25px" }}
                            />
                          )}
                        </span>
                      </span>
                    </td>
                    <td className="text-center">
                      <button
                        onClick={() =>
                          handleData(item.Lots, item.Arbitrator_id)
                        }
                        variant="success"
                        className="custBtn"
                      >
                        Show Data
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* To display the Data of fetched Lot */}
      {showData && (
        <div className="mt-3">
          {data.map((item, index) => (
            <>
              <button
                className={` ${waDone || item.wa !== null  ? "disabledBtn" : "custBtn"}`}
                onClick={handleWhatsapp}
                disabled={waDone}
              >
                WhatsApp
              </button>

              <button
                className={`ms-3 ${mailDone || item.mail !== null ? "disabledBtn" : "custBtn"}`}
                onClick={handleMail}
                disabled={mailDone}
              >
                Mail
              </button>

              <button
                className={`ms-3 ${smsDone || item.sms !== null ? "disabledBtn" : "custBtn"}`}
                onClick={handleSMS}
                disabled={smsDone}
              >
                Message
              </button>
            </>
          ))}
          <div className="table-container mt-3">
            <div className="table-wrapper">
              <table className="responsive-table">
                <thead>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </thead>
                <tbody>
                  {data.length > 0 &&
                    data.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          maxHeight: "50px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        className="text-center custom_fz"
                      >
                        {headers.map((header) => (
                          <td key={header}>{item[header]}</td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <>
              {/* <Pagination className="justify-content-center">
              {pageNumbers.map((number) => (
                <Pagination.Item
                  key={number}
                  active={number === currentPage}
                  onClick={() => setCurrentPage(number)}
                >
                  {number}
                </Pagination.Item>
              ))}
            </Pagination> */}
            </>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ReferenceDraftServices;
