import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Pagination } from "react-bootstrap";
import LoadingSpinner from "./LoadingSpinner";
import "./uploadexcel.css";
import { toast, ToastContainer } from "react-toastify";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// Import the Viewer styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import CustomStepper from "./CustomStepper";
import { API_BASE_URL } from "../utils/constants";
import ReusableTable from "./ReusableTable";
import ClearForm from "./ClearForm";

const steps = ["View Data", "Generate RD", "Upload RD", "Completed"];

const GenerateDraft = () => {
  const [refNotCreatedLots, setRefNotCreatedLots] = useState([]);
  const [data, setData] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]); //the clients Data to populate dropdown
  const [selectedProduct, setSelectedProduct] = useState([]); //to add the product from the dropdown
  const [arbitrator, setArbitrator] = useState([]); //to add the product from the dropdown
  const [whatsappStatus, setWhatsappStatus] = useState([]); //to add the product from the dropdown
  const [error, setError] = useState(null);
  const [error1, setError1] = useState(null);
  const [error2, setError2] = useState(null);
  const [selectedProductID, setSelectedProductID] = useState(null);
  const [selectedClientID, setSelectedClientID] = useState(null);
  const [selectedLotNo, setSelectedLotNo] = useState(null);
  const [selectedArbitratorID, setSelectedArbitratorID] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [status, setStatus] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [clearForm, setClearForm] = useState(false);
  const [responses, setResponses] = useState([]);
  const [allData, setAllData] = useState([]);
  const [whatsAppStatus, setWhatsAppStatus] = useState(null);
  const [activeStep, setActiveStep] = React.useState(0); //for stepper component
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Set to 10 records per page

  // for  Data
  const [currentPage1, setCurrentPage1] = useState(1);
  const [itemsPerPage1] = useState(10);
  const [pageNumbers1, setPageNumbers1] = useState([]);

  // For Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // for progress bar
  // const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0); // Progress in percentage
  const [completedCases, setCompletedCases] = useState(0); // Track how many cases have been processed

  // Refrence Draft NOt Creates Lots Arbitrator Wise
  useEffect(() => {
    const fetchRefNotCreatedLots = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/pendingRefLots`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const parsedNotServedLots = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        setRefNotCreatedLots(parsedNotServedLots);
      } catch (error) {
        // setError1(error.message);
      }
    };

    fetchRefNotCreatedLots();
  }, []);

  // console.log(refNotCreatedLots);


  // To fetch Clients DaTA
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/Client`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const parsedClient = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        // console.log(parsedClient);
        setSelectedClient(parsedClient);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  console.log(selectedClient);

  // API Call for Product data
  useEffect(() => {
    const fetchProduct = async () => {
      // setLoading(true);
      setError(null); // Reset error state
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/products?client_id=${selectedClientID}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const parsedProducts = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        console.log(parsedProducts);
        setSelectedProduct(parsedProducts); // Set products data
      } catch (error) {
        setError1(error);
      } finally {
        setLoading1(false);
      }
    };

    fetchProduct();
  }, [selectedClientID]);

  // API Call for Arbitrator
  useEffect(() => {
    const fetchArbitrator = async () => {
      // setLoading(true);
      setError(null); // Reset error state
      try {
        const response = await fetch(
          // "http://88.135.73.195/api/arbitrator"
          `${API_BASE_URL}/api/arbitrator`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const parsedArbitrators = Array.isArray(result)
          ? result
          : JSON.parse(result); // Ensure parsedArbitrators is an array
        console.log(parsedArbitrators);
        setArbitrator(parsedArbitrators); // Set products data
      } catch (error) {
        setError1(error);
      } finally {
        setLoading(false);
      }
    };

    fetchArbitrator();
  }, []);

  useEffect(() => {
    const totalPages1 = Math.ceil(data.length / itemsPerPage1);
    const pages1 = [];
    for (let i = 1; i <= totalPages1; i++) {
      pages1.push(i);
    }
    setPageNumbers1(pages1);
  }, [data]);

  console.log(arbitrator);

  // Function to move to a specific step in Stepper Component
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  // Show Data Logic Goes here
  const handleData = async (item) => {
    setSelectedLotNo(item.Lot_no);
    setSelectedClientID(item.Client_id);
    setSelectedProductID(item.Product_id);
    setSelectedArbitratorID(item.Arbitrator_id);
    setLoading(true); // Start loading before fetching data
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/RefLots?Lot_no=${item.Lot_no}&Client_id=${item.Client_id}&Product_id=${item.Product_id}&Arb_id=${item.Arbitrator_id}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      const parsedData = Array.isArray(result) ? result : JSON.parse(result);
      const updatedData = parsedData.map((item, index) => {
        const { SR_No, assign_id, Arbitrator_id, Case_id, UPLODED_DATE, ...rest } =
          item;
        return {
          SrNo: index + 1,
          ...rest,
        };
      });
      console.log(updatedData);
      setAllData(parsedData);
      setData(updatedData);
      setShowTable(true);
      handleStepChange(1);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // console.log(allData);
  // console.log(data);
  // console.log(selectedClientID);
  // console.log(selectedProductID);
  // console.log(selectedLotNo);
  // console.log(selectedArbitratorID);

  // Pagination Logic
  const indexOfLastItem1 = currentPage1 * itemsPerPage1;
  const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage1;
  const currentItems1 = data.slice(indexOfFirstItem1, indexOfLastItem1);


  // Loading Spinner Compenent
  if (loading) return <LoadingSpinner />;


  // to handle the generation of reference Draft
  const handleGenerateDraft = async () => {
    setLoading(true); // Start loading before fetching data
    try {
      // Fetch the PDF file from the API
      const response = await fetch(
        `${API_BASE_URL}/api/Refletter?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Convert the response to a Blob
      const pdfBlob = await response.blob();
      console.log(pdfBlob);
      // Create a URL for the Blob
      const pdfUrl1 = URL.createObjectURL(pdfBlob);
      console.log(pdfUrl);
      // Set the PDF URL to the state
      setPdfUrl(pdfUrl1);
      // setPdfUrl(pdfUrl);
      setShowPDF(true);
      handleStepChange(2);
    } catch (error) {
      console.error("Error fetching and displaying the PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function for upload thr ReferenceDraft
  const handleUploadDraft = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/SaveRefCase?Lot_no=${selectedLotNo}&Client_id=${selectedClientID}&Product_id=${selectedProductID}&Arb_id=${selectedArbitratorID}`
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
        );
      }

      const result = await response.json(); // Process the response
      console.log(result);
      setShowServices(true);
      setClearForm(true);

      handleStepChange(3);
      handleStepChange(4);
    } catch (error) {
      console.error("Error uploading data:", error);
      alert(`Error uploading data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };


  // Calculate the total number of pages
  const totalPages = Math.ceil(refNotCreatedLots.length / rowsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  // Calculate the starting and ending index of the rows for the current page
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentRows = refNotCreatedLots.slice(
    startIndex,
    startIndex + rowsPerPage
  );

  // Pagination logic for showing only 5 buttons at a time
  const maxPagesToShow = 5;
  const startPage = Math.max(
    1,
    Math.min(
      currentPage - Math.floor(maxPagesToShow / 2),
      totalPages - maxPagesToShow + 1
    )
  );
  const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
  const displayedPages = pageNumbers.slice(startPage - 1, endPage);

  // Handlers for pagination
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          {/* <div className="col-md-12 mx-auto">
            <CustomStepper
              steps={steps}
              activeStep={activeStep}
              onStepChange={handleStepChange}
              color="green" // You can change the color if needed
            />
          </div> */}
        </div>

        {/* Top Lable */}
        <div className="row">
          {!clearForm && (
            <div className="col-md-6">
              <h4 className="mt-3 ms-1">Generate Reference Draft</h4>
            </div>
          )}
          <div className="col-md-4"></div>
          <div className="col-md-2 mt-3">
            {showTable && !showPDF && (
              <button
                // className="btn btn-primary getDataBtn"
                className="custBtn"
                onClick={handleGenerateDraft}
              >
                Generate RD
              </button>
            )}
            {showTable && showPDF && !showServices && (
              <Button className="custBtn" onClick={handleUploadDraft}>
                Upload RD
              </Button>
            )}
          </div>
        </div>

        {!clearForm && !showTable && (
          <>
            <div className="row table-container">
              <div className="col-md-12 mx-auto table-wrapper">
                <table className="responsive-table">
                  <thead className="text-center">
                    <tr className="table-info">
                      <th scope="col" className="text-center">
                        Sr No
                      </th>
                      <th scope="col" className="text-center">
                        Lots
                      </th>
                      <th scope="col" className="text-center">
                        Arbitrator
                      </th>
                      {/* <th scope="col" className="text-center">
                        Uploaded Date
                      </th> */}
                      <th scope="col" className="text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {currentRows.map((item, index) => (
                      <tr key={item.id}>
                        <td className="text-center">
                          {startIndex + index + 1}
                        </td>
                        <td className="text-center">{item.Lots}</td>
                        <td className="text-left">{item.Arb_name}</td>
                        {/* <td className="text-center">{item.UPLODED_DATE}</td> */}
                        <td className="text-center">
                          <button
                            onClick={() => handleData(item)}
                            variant="success"
                            className="custBtn"
                          >
                            Create Drafts
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination Controls */}
                {totalPages > 1 && (
                  <Pagination className="justify-content-center mt-3">
                    {/* Left Arrow */}
                    <Pagination.Prev
                      onClick={handlePrevious}
                      disabled={currentPage === 1}
                    >
                      <FaChevronLeft />
                    </Pagination.Prev>

                    {/* Page Number Buttons */}
                    {displayedPages.map((number) => (
                      <Pagination.Item
                        key={number}
                        active={number === currentPage}
                        onClick={() => setCurrentPage(number)}
                      >
                        {number}
                      </Pagination.Item>
                    ))}

                    {/* Right Arrow */}
                    <Pagination.Next
                      onClick={handleNext}
                      disabled={currentPage === totalPages}
                    >
                      <FaChevronRight />
                    </Pagination.Next>
                  </Pagination>
                )}
              </div>
            </div>
          </>
        )}

        {!showPDF && showTable && (
          <div className="row mt-3">
            <div className="col-md-12">
              <ReusableTable
                data={currentItems1}
                currentPage={currentPage1}
                pageNumbers={pageNumbers1}
                setCurrentPage={setCurrentPage1}
              />
            </div>
          </div>
        )}

        {showPDF && !showServices && (
          <div className="row mt-3">
            <div className="col-md-12">
              <iframe
                src={pdfUrl}
                style={{ width: "100%", height: "100vh" }}
                title="PDF Viewer"
              />
            </div>
          </div>
        )}

        {clearForm && (
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center ">
              {/* <ClearForm />
              <Refresh
                message="Refernce Draft Created Successfully"
                redirectPath="/instdashboard" // Specify the redirect path
              /> */}
              <ClearForm message="Reference Draft Created  Successfully!" redirectPath="/instdashboard" />
            </div>
          </div>
        )}

        <ToastContainer />
      </div>
    </>
  );
};

export default GenerateDraft;
