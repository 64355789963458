import React, { useState } from "react";
import Topbar from "../Componants/Topbar";
import Sidebar from "../Componants/Sidebar";
import "./pages.css";
import { Form } from "react-bootstrap";
import AssignArbitrator from "../Componants/AssignArbitrator";
import { Outlet } from "react-router-dom";
import { useAuth } from "../Componants/AuthProvider";
import { Navigate } from "react-router-dom";

const InstDashboard = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("Resolution");
  const [isOpen, setIsOpen] = useState(true);

  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  } else {
    console.log(user.User_name);
  }

  const menuItems = [
    {
      name: "Dashboard",
      icon: "home", // Material Icon name
      link: "/instdashboard",
    },
    {
      name: "Upload A Lot",
      icon: "upload",
      link: "/instdashboard/uploadalot",
    },
    {
      name: "Assign Arbitrator",
      icon: "sort",
      link: "/instdashboard/assignarb",
    },
    // {
    //     name: 'Generate Reference Draft',
    //     icon: 'edit_square',
    //     link: '/instdashboard/referencedraft',
    // },
    {
      name: "Reference Draft",
      icon: "edit_square",
      submenu: [
        {
          name: "Generate RD",
          link: "/instdashboard/referencedraft/generate-rd",
          icon: "person",
        },
        {
          name: "Services",
          link: "/instdashboard/referencedraft/services",
          icon: "category",
        },
        {
          name: "Reports",
          link: "/instdashboard/referencedraft/reports",
          icon: "description",
        },
        // { name: 'Claim', link: '#', icon: 'security' }
      ],
    },
  ];

  const handleMenuItemClick = (name) => {
    setSelectedMenuItem(name);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="main-div">
      <Topbar isOpen={isOpen} />
      <Sidebar
        menuItems={menuItems}
        user={user.User_name}
        onMenuItemClick={handleMenuItemClick}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleSidebar={toggleSidebar}
      />
      <div className={`${isOpen ? "" : "sideCollapse"} mainn-content p-3`}>
        <Outlet user={user.User_name}/>
      </div>
    </div>
  );
};

export default InstDashboard;
