import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is imported
import { Pagination } from "react-bootstrap";
import FileUploadForm from "./FileUploadForm";
import './VirtualMeeting.css';
import { API_BASE_URL } from "../utils/constants";

const VirtualMeeting = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [editValue, setEditValue] = useState(null);
  const [zoomMeetingId, setZoomMeetingId] = useState(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [addDetails, setAddDetails] = useState(false);

  // Dummy function to fetch Zoom meeting ID based on the selected date
  const fetchZoomMeetingId = (date) => {
    // Replace this with actual logic to fetch Zoom meeting ID
    const dummyZoomMeetingId = "123-456-7890"; // Example Zoom meeting ID
    return dummyZoomMeetingId;
  };

  const handleDateChange = (date) => {
    // const dateStr = date.toISOString().split("T")[0];
    // setEditValue(dateStr); // Update the state with the formatted date
    // setSelectedDate(date); // Update selectedDate state
    // const meetingId = fetchZoomMeetingId(date);
    // setZoomMeetingId(meetingId);
    // formatDate(editValue);
    setSelectedDate(date);
    if (date) {
      // const options = { year: "numeric", month: "short", day: "numeric" };
      const formatted = date.toLocaleDateString("en-US");
      // const formatted = date.toLocaleDateString("en-US", options);
      setFormattedDate(formatted);
    } else {
      setFormattedDate("");
    }
  };
  console.log(formattedDate);

  function formatDate(date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Split the input date string by hyphens
    const parts = date.split("-");
    const year = parts[0];
    const month = months[parseInt(parts[1], 10) - 1];
    const day = parseInt(parts[2], 10);
    return console.log("${month} ${day}, ${year}");
  }

  const handleMeetingIdClick = () => {
    if (zoomMeetingId) {
      // Replace with your Zoom meeting URL or desired action
      window.open(`https://zoom.us/j/${zoomMeetingId}`, "_blank");
    }
  };

  // const handleAppointments = async () => {};

  const handleAppointments = async (date) => {

    const meetingId = fetchZoomMeetingId(date);
    setZoomMeetingId(meetingId);
    const dataAppointments = [{ Hearing_date: formattedDate }];
    console.log(dataAppointments);
    try {
      const response = await fetch(
        // "http://arb.resolutionexperts.in/api/GetOpp",
        `${API_BASE_URL}/api/GetOpp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ case: dataAppointments }),
        }
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to upload data: ${response.status} ${response.statusText} - ${errorText}`
        );
      }
      const result = await response.json(); // Process the response
      console.log("Upload response:", result);
      const parsedData = JSON.parse(result);
      const updatedData = parsedData.map((item) => {
        const {
          // SR_No,
         
          Acc_id,
          Arb_Assign_id,
          Acc_date,
          Hearing_date,
          Video_link,
          Link_id,
          Password,
          No_of_cases,
          Rate,
          ...rest
        } = item;
        return {
          action: <button className="custBtn" onClick={handleAddDetails}>Add Details</button>,
          ...rest
        };
      });
      setData(updatedData);
      // setZoomMeetingId(true);
    } catch (error) {
      console.error("Error uploading data:", error);
      // alert(`Error uploading data: ${error.message}`);
    } finally {
      // setLoading(false);
    }
  };

  console.log(data);


  
  const handleAddDetails = () => {
    setAddDetails(true);
  };



  const headers = data.length > 0 ? Object.keys(data[0]) : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  console.log(currentItems);

  const totalPagesToShow = 5; // Maximum number of page buttons to show

  const generatePaginationItems = () => {
    const pageItems = [];
    let startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

    if (endPage - startPage + 1 < totalPagesToShow) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  return (
    <div className="container mt-4">
      <h2>Virtual Meeting</h2>
      <div className="form-group">
        <div className="row align-items-center my-5">
          {/* <div className="col-auto">
            <label htmlFor="datePicker" className="form-label">Select Virtual Hearing Date</label>
          </div>
          <div className="col">
            <DatePicker
              id="datePicker"
              selected={selectedDate}
              onChange={handleDateChange}
              placeholderText="Select a date"
              dateFormat="MMMM d, yyyy"
              className="form-control date-picker-sm" // Apply custom class for size adjustment
            />
          </div> */}
          <div className="col-md-2">
            <label htmlFor="datePicker" className="form-label">
              Select Date :-
            </label>
          </div>
          <div className="col-md-3">
            <DatePicker
              id="datePicker"
              selected={selectedDate}
              onChange={handleDateChange}
              placeholderText="Select a date"
              // dateFormat="MMMM d, yyyy"
              dateFormat="MM/dd/yyyy"
              className="form-control date-picker-sm" // Apply custom class for size adjustment
            />
          </div>
          <div className="col-md-3">
            {/* <button className="btn btn-primary" onClick={handleAppointments}> */}
            <button className="custBtn" onClick={handleAppointments}>
              Show Appointments
            </button>
          </div>
          {zoomMeetingId && (
            <div className="col-md-4">
              <button
                // className="btn btn-primary"
                className="custBtn"
                onClick={handleMeetingIdClick}
              >
                Join Zoom Meeting: {zoomMeetingId}
              </button>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              {data.length > 0 && (
                <table className="table table-striped table-bordered table-hover mt-3 text-center">
                  <thead>
                    <tr>
                      {headers.map((header) => (
                        <th key={header}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          maxHeight: "50px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        className="text-center custom_fz"
                      >
                        {headers.map((header) => (
                          <td key={header}>{item[header]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            {totalPages > 1 && (
              <Pagination className="justify-content-center">
                <Pagination.Prev
                  onClick={() =>
                    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))
                  }
                />
                {generatePaginationItems()}
                <Pagination.Next
                  onClick={() =>
                    setCurrentPage((prev) =>
                      prev < totalPages ? prev + 1 : prev
                    )
                  }
                />
              </Pagination>
            )}
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-10">
          {/* {addDetails && <FileUploadForm />} */}
          <FileUploadForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualMeeting;
